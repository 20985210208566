import * as React from 'react';
import {
    GridRowModes,
    GridToolbar,
    GridToolbarContainer,
} from '@mui/x-data-grid-premium';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import type {
    GridRowModesModel
} from '@mui/x-data-grid-premium';
import { randomId } from '@mui/x-data-grid-generator';
import { useDispatch } from 'react-redux';

interface EditToolbarProps {
    stackRank: string;
    setRows: (payload: unknown) => {
        payload: unknown;
        type: string;
    };
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

export const EditToolbar = (props: EditToolbarProps): React.JSX.Element => {
    const { setRows, setRowModesModel, stackRank } = props;
    const dispatch = useDispatch();
    const handleClick = (): void => {
        const id = randomId();
        dispatch(setRows({
            id,
            stackRank
        }));
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {
                fieldToFocus: 'name',
                mode: GridRowModes.Edit,
            },
        }));
    };

    return (
        <GridToolbarContainer>
            <GridToolbar />
            <Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    );
};

export default EditToolbar;

/* Resource Magic 2024 */