import '../../style.css';
import Box from '@mui/material/Box';
import CountrySelector from '../../components/countrySelector';
import React from 'react';
import StateSelector from '../../components/stateSelector';
import TextField from '@mui/material/TextField';

export const Address = (): React.JSX.Element => {
    return (
        <Box
            component='form'
            sx={{
                '& .MuiTextField-root': {
                    m: 1,
                    width: '25ch'
                },
                width: 450,
            }}
            noValidate
            autoComplete='off'
        >
            <fieldset className='address-box'>
                <legend className='address-legend'>Address</legend>
                <CountrySelector />
                <TextField
                    required
                    id='outlined-required'
                    label='Street Address or P.O. Box'
                    // defaultValue={employeeName}
                    placeholder='Street Address or P.O. Box'
                    size='small'
                    style={{ width: 450 }}
                    margin='none'
                // onChange={onChange}
                />
                <br />
                <TextField
                    required
                    id='outlined-required'
                    label='Apt, suite, unit, building, floor, etc.'
                    // defaultValue={employeeName}
                    placeholder='Apt, suite, unit, building, floor, etc.'
                    size='small'
                    style={{ width: 500 }}
                    margin='none'
                // onChange={onChange}
                />
                <div className='city-state'>
                    <TextField
                        required
                        id='outlined-required'
                        label='City'
                        // defaultValue={employeeName}
                        placeholder='City'
                        size='small'
                        style={{ width: 220 }}
                        margin='none'
                    // onChange={onChange}
                    />
                    <StateSelector />
                </div>

                <TextField
                    required
                    id='outlined-required'
                    label='ZIP Code'
                    // defaultValue={employeeName}
                    placeholder='ZIP Code'
                    size='small'
                    style={{ width: 220 }}
                    margin='none'
                // onChange={onChange}
                />
            </fieldset>
        </Box>
    );
};

export default Address;
