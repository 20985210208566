import type { EmployeeType, FetchType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';

const INIT_EID = -1;

export const fetchGetEmployees = createAsyncThunk<EmployeeType[]>(
    'employees/getEmployees',
    async () => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',
        };
        const response = await fetch(`${SERVER_IP}/employees/employees`, init);
        const data = (await response.json()) as EmployeeType[];
        return data;
    },
);

export const fetchPostEmployee = createAsyncThunk(
    'employees/postEmployee',
    async (employee: { employee: EmployeeType }) => {
        const init: RequestInit = {
            body: JSON.stringify(employee),
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'POST',
        };

        const data = await fetch(`${SERVER_IP}/employees/employee`, init);
        const json = (await data.json()) as Promise<unknown>;
        return json;
    },
);

const initialState: FetchType<EmployeeType[]> = {
    error: null,
    fetchType: [] as EmployeeType[],
    status: 'idle',
};

export const employeesSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetEmployees.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEmployees.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetEmployees.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPostEmployee.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostEmployee.fulfilled, (state, action) => {
                const response = action.payload as {
                    eid: number;
                    old_eid: number;
                    status: string;
                };
                state.status = 'succeeded';
                const oldEid = response.old_eid;
                const eid = response.eid;
                if (oldEid !== eid) {
                    state.fetchType.forEach((e) => {
                        if (e.eid === INIT_EID) {
                            e.eid = eid;
                        }
                    });
                }
            })
            .addCase(fetchPostEmployee.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'employees',
    reducers: {
        addEmployee: (state, action) => {
            const { id } = action.payload;
            const addRow: EmployeeType = {
                domains: [],
                eid: -1,
                email: '',
                id,
                isActive: true,
                isNew: true,
                jobTitle: '',
                name: '',
                notes: '',
                phone: '',
                roles: [],
            };
            state.fetchType.push(addRow);
        },
        deleteEmployee: (state, action) => {
            state.fetchType = state.fetchType?.filter((row) => row.id !== action.payload);
        },
        updateEmployee: (state, action) => {
            const employee = action.payload as EmployeeType;
            const updatedRow: EmployeeType = {
                ...employee,
                isNew: false
            };
            state.fetchType = state.fetchType.map((row) => {
                return row.eid === employee.eid ? updatedRow : row;
            });
        },
    },

});

export const selectAllEmployees = (state: Store): EmployeeType[] => state.employees.fetchType;
export const { deleteEmployee, updateEmployee, addEmployee } = employeesSlice.actions;
export default employeesSlice.reducer;
