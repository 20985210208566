import '../../style.css';
import * as React from 'react';
import type { EmailContactType, EmailType } from '../../types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EmailTwoTone from '@mui/icons-material/EmailTwoTone';
import { EnhancedTable } from '../../components/enhancedTable/enhancedTable';
import FormControl from '@mui/material/FormControl';
import type { HeadCell } from '../../components/enhancedTable/enhancedTable';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { emailContactsType } from '../../types';

export type { HeadCell };

const { useEffect, useState } = React;

const headCells: readonly HeadCell<EmailType>[] = [
    {
        disablePadding: false,
        id: 'contactType',
        label: 'Contact Type',
        numeric: false,
        width: 100,
    },
    {
        disablePadding: true,
        id: 'email',
        label: 'Email',
        numeric: false,
        width: 200,
    },
    {
        disablePadding: false,
        id: 'priority',
        label: 'Priority',
        numeric: false,
        width: 100,
    },
];

export interface EmailControlProps {
    emailContacts: EmailType[];
    setEmailContacts: React.Dispatch<React.SetStateAction<EmailType[]>>;
}

export const EmailControl = (props: EmailControlProps): React.JSX.Element => {
    const { emailContacts, setEmailContacts } = props;
    const [rows, setRows] = useState(emailContacts);
    const [contactType, setContactType] = React.useState<EmailContactType>('Office');
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        setEmailContacts(rows);
    }, [rows]);

    const handleChange = (event: SelectChangeEvent): void => {
        setContactType(event.target.value as EmailContactType);
    };

    const onClick = (): void => {
        if (email === '') {
            return;
        }
        const emailRegEx = /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;
        if (emailRegEx.test(email) === false) {
            return;
        }
        setRows((pRows) => [...pRows, {
            contactType,
            email,
            priority: ''
        }]);
        setEmail('');
        setEmailContacts((pRows) => [...pRows, {
            contactType,
            email,
            priority: ''
        }]);
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const value = e.target.value;
        setEmail(value);
    };

    const setAsPrimary = (index: number) => (prevRows: EmailType[]): EmailType[] => {
        const newRows: EmailType[] = prevRows.map((row) => ({
            ...row,
            priority: ''
        }));
        newRows[index] = {
            ...newRows[index],
            priority: 'Primary'
        };
        setEmailContacts(newRows);
        return newRows;
    };

    const setAsSecondary = (index: number) => (prevRows: EmailType[]): EmailType[] => {
        const newRows: EmailType[] = prevRows.map((row) => ({
            ...row,
            priority: ''
        }));
        newRows[index] = {
            ...newRows[index],
            priority: 'Secondary'
        };
        setEmailContacts(newRows);
        return newRows;
    };

    const actions = new Map();
    actions.set('Set as Primary', setAsPrimary);
    actions.set('Set as Secondary', setAsSecondary);

    return (
        <Box
            component='form'
            sx={{
                '& .MuiTextField-root': {
                    m: 1,
                    width: '25ch'
                },
                width: 500,
            }}
            noValidate
            autoComplete='off'
        >
            <fieldset>
                <legend style={{ textAlign: 'left' }}>Email</legend>
                <Box sx={{
                    alignItems: 'flex-end',
                    display: 'flex',
                }}>
                    <EmailTwoTone sx={{
                        color: 'action.active',
                        mr: 0.1,
                        my: 0.5
                    }} />
                    <TextField
                        value={email}
                        required
                        id='email'
                        label='Email'
                        placeholder='Enter Email'
                        size='small'
                        margin='none'
                        variant='standard'
                        onBlur={onBlur}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    />
                    <FormControl variant='standard' sx={{
                        m: 1,
                        maxWidth: 100,
                        minWidth: 100,
                    }}>
                        <InputLabel id='demo-simple-select-standard-label'>Contact Type</InputLabel>
                        <Select
                            required
                            labelId='demo-simple-select-standard-label'
                            id='demo-simple-select-standard'
                            value={contactType}
                            onChange={handleChange}
                            label='Contact Type'
                        >
                            {emailContactsType.map((contactType1, index) => {
                                return (
                                    <MenuItem key={index} value={contactType1}>
                                        {contactType1}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Button
                        onClick={onClick}
                        variant='outlined'
                        size='small'
                        sx={{
                            color: 'action.active',
                            ml: 0.3,
                            my: 0.7
                        }}
                    >
                        Add
                    </Button>
                </Box>
                <EnhancedTable
                    additionalContextMenuOptions={actions}
                    headCells={headCells}
                    rows={rows}
                    setRows={setRows}

                />
            </fieldset>
        </Box>
    );
};

export default EmailControl;
