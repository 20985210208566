import * as React from 'react';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    useGridApiContext,
} from '@mui/x-data-grid-premium';
import type { EmployeeSkillsType, EmployeeType, ProjectCompletionStatusType, Store, } from '../../types';
import type {
    GridActionsCellItemProps,
    GridColDef,
    GridEventListener,
    GridRenderEditCellParams,
    GridRowId,
    GridRowModel,
    GridRowModesModel,
    GridSlots
} from '@mui/x-data-grid-premium';
import {
    deleteCompletionStatus,
    fetchGetProjectCompletionStatus,
    fetchPostProjectCompletionStatus,
    selectAllProjectCompletions,
    addCompletionStatus as setRows,
    updateCompletionStatus,
} from '../slices/projectCompletionSlice';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import EditToolbar from '../components/editToolbar';
import type { FetchStatusType } from '../../types';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { UnknownAction } from '@reduxjs/toolkit';
import { selectAllEmployees } from '../slices/employeesSlice';
import { selectAllEmployeeSkills } from '../slices/employeeSkillsSlice';


const { useState, useRef, useEffect, useLayoutEffect } = React;

export type SettingsType =
    'name' | 'role' | 'domain' | 'skill' | 'level';

export const ProjectCompletionStatus = (props: { stackRank: string }): React.JSX.Element => {
    const { stackRank } = props;

    const dispatch = useDispatch();

    const employees: EmployeeType[] = useSelector(selectAllEmployees) ?? [];
    const employeesSkills: EmployeeSkillsType[] = useSelector(selectAllEmployeeSkills) ?? [];
    const employeeNames: string[] = employees?.map(e => e.name) ?? [];

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const fetchStatus: FetchStatusType = useSelector(
        (state: Store) => state.projectCompletionStatus.status,
    );
    const rows =
        useSelector(selectAllProjectCompletions)?.find((req) => req.stackRank === stackRank)
            ?.completionStatus ?? [];
    const isEdit = useRef(false);

    useEffect(() => {
        if (fetchStatus === 'idle') {
            dispatch(fetchGetProjectCompletionStatus() as unknown as UnknownAction);
        }
    }, []);

    useEffect(() => {
        if (!isEdit.current) {
            return;
        }
        isEdit.current = false;
        const saveRows: ProjectCompletionStatusType = {
            completionStatus: rows.map((row) => ({
                domain: row.domain,
                duration: row.duration,
                eid: row.eid,
                id: row.id,
                level: row.level,
                role: row.role,
                skill: row.skill,
            })),
            stackRank,
        };

        dispatch(
            fetchPostProjectCompletionStatus(
                { completionStatus: saveRows }) as unknown as UnknownAction,
        );
    }, [rows]);

    const settingSelection = {
        domain: [] as string[],
        level: [] as string[],
        name: employeeNames,
        role: [] as string[],
        skill: [] as string[],
    };


    const SelectionNameCell = (props1: GridRenderEditCellParams): React.JSX.Element => {
        const { id, value, field, hasFocus, row } = props1;
        const select = settingSelection[field as SettingsType];
        const apiRef = useGridApiContext();
        const ref = useRef<JSX.Element>();

        const employee = employees.find(e => e.eid === row.eid);
        settingSelection.role = employee?.roles ?? [];
        settingSelection.domain = employee?.domains ?? [];
        settingSelection.skill = employeesSkills.find(e => e.eid === row.eid)?.skills.map(s => s.skill) ?? [];

        useLayoutEffect(() => {
            if (hasFocus) {
                (ref.current as unknown as any)?.focus();
            }
        }, [hasFocus]);

        const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const { value: newValue } = event.target;
            void apiRef.current.setEditCellValue({
                field,
                id,
                value: newValue,
            });

            const currentEmployee = employees.find(e => e.name === newValue);
            const currentRow = rows.find(r => r.id === id);
            processRowUpdate({
                ...currentRow,
                eid: currentEmployee?.eid
            });
        };

        const items = [...select ?? []].map((d) =>
            <MenuItem key={d} value={d}>
                {d}
            </MenuItem>
        );

        return (
            <Select
                placeholder='Choose one…'
                ref={ref}
                style={{ width: 150 }}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={value}
                label='Domain'
                onChange={handleValueChange as any}
            >
                {items}
            </Select>
        );
    };

    const SelectionRoleDomainCell = (props1: GridRenderEditCellParams): React.JSX.Element => {
        const { id, value, field, hasFocus, row } = props1;
        const select = settingSelection[field as SettingsType];
        const apiRef = useGridApiContext();
        const ref = React.useRef<JSX.Element>();

        useLayoutEffect(() => {
            if (hasFocus) {
                (ref.current as unknown as any)?.focus();
            }
        }, [hasFocus]);

        const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const { value: newValue } = event.target;
            void apiRef.current.setEditCellValue({
                field,
                id,
                value: newValue,
            });
        };

        const items = [...select ?? []].map((d) =>
            <MenuItem key={d} value={d}>
                {d}
            </MenuItem>
        );

        return (
            <Select
                placeholder='Choose one…'
                ref={ref}
                style={{ width: 150 }}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={value}
                label='Domain'
                onChange={handleValueChange as any}
            >
                {items}
            </Select>
        );
    };

    const SelectionSkillCell = (props1: GridRenderEditCellParams): React.JSX.Element => {
        const { id, value, field, hasFocus, row } = props1;
        const select = settingSelection[field as SettingsType];
        const apiRef = useGridApiContext();
        const ref = React.useRef<JSX.Element>();

        useLayoutEffect(() => {
            if (hasFocus) {
                (ref.current as unknown as any)?.focus();
            }
        }, [hasFocus]);

        const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const { value: newValue } = event.target;
            void apiRef.current.setEditCellValue({
                field,
                id,
                value: newValue,
            });
        };

        const items = [...select ?? []].map((d) =>
            <MenuItem key={d} value={d}>
                {d}
            </MenuItem>
        );

        return (
            <Select
                placeholder='Choose one…'
                ref={ref}
                style={{ width: 150 }}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={value}
                label='Domain'
                onChange={handleValueChange as any}
            >
                {items}
            </Select>
        );
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const processRowUpdate = (newRow: GridRowModel): {
        isNew: boolean;
    } => {
        const updatedRow = {
            ...newRow,
            isNew: false
        };
        dispatch(updateCompletionStatus({
            newRow,
            stackRank,
        }));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel): void => {
        setRowModesModel(newRowModesModel);
    };

    const handleEditClick = (id: GridRowId) => (): void => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.Edit },
        });
    };

    const handleSaveClick = (id: GridRowId) => (): void => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
        });
        isEdit.current = true;
    };

    const handleCancelClick = (id: GridRowId) => (): void => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {
                ignoreModifications: true,
                mode: GridRowModes.View,
            },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.isNew ?? false) {
            dispatch(deleteCompletionStatus(id));
        }
    };

    const handleDeleteClick = (id: GridRowId) => (): void => {
        console.log(id);
        dispatch(deleteCompletionStatus({
            id,
            stackRank
        }));
        isEdit.current = true;
    };

    const columns: GridColDef[] = [
        {
            field: 'eid',
            headerName: 'EID',
            type: 'number',
            valueGetter: (value, row): number => {
                const eid = row.eid;
                return eid;
            },
        },
        {
            editable: true,
            field: 'name',
            headerName: 'Name',
            renderEditCell: (params) => <SelectionNameCell {...params} />,
            type: 'string',
            valueGetter: (value, row): string => {
                const name = employees.find(e => e.eid === row.eid)?.name ?? '';
                return name;
            },
        },
        {
            field: 'tile',
            headerName: 'Title',
            type: 'string',
            valueGetter: (value, row): string => {
                const title = employees.find(e => e.eid === row.eid)?.jobTitle ?? '';
                return title;
            },
        },
        {
            field: 'email',
            headerName: 'Email',
            type: 'string',
            valueGetter: (value, row): string => {
                const email = employees.find(e => e.eid === row.eid)?.email ?? '';
                return email;
            },
        },
        {
            field: 'phone',
            headerName: 'Phone',
            type: 'string',
            valueGetter: (value, row): string => {
                const phone = employees.find(e => e.eid === row.eid)?.phone ?? '';
                return phone;
            },
        },
        {
            editable: true,
            field: 'role',
            headerName: 'Role',
            renderEditCell: (params) => <SelectionRoleDomainCell {...params} />,
            type: 'string',
        },
        {
            editable: true,
            field: 'domain',
            headerName: 'Domain',
            renderEditCell: (params) => <SelectionRoleDomainCell {...params} />,
            type: 'string',
        },
        {
            editable: true,
            field: 'skill',
            headerName: 'Skill',
            renderEditCell: (params) => <SelectionSkillCell {...params} />,
            type: 'string',
        },
        {
            field: 'level',
            headerName: 'Level',
            type: 'string',
            valueGetter: (value, row): string => {
                const level = employeesSkills
                    .find(e => e.eid === row.eid)?.skills.find(s => s.skill === row.skill)?.level ?? '';
                return level;
            },
        },
        {
            editable: true,
            field: 'duration',
            headerName: 'Duration',
            type: 'number',
        },
        {
            cellClassName: 'actions',
            field: 'actions',
            getActions: ({ id }): React.ReactElement<GridActionsCellItemProps>[] => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key='0'
                            icon={<SaveIcon />}
                            label='Save'
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key='1'
                            icon={<CancelIcon />}
                            label='Cancel'
                            className='textPrimary'
                            onClick={handleCancelClick(id)}
                            color='inherit'
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key='2'
                        icon={<EditIcon />}
                        label='Edit'
                        className='textPrimary'
                        onClick={handleEditClick(id)}
                        color='inherit'
                    />,
                    <GridActionsCellItem
                        key='3'
                        icon={<DeleteIcon />}
                        label='Delete'
                        onClick={handleDeleteClick(id)}
                        color='inherit'
                    />,
                ];
            },
            headerName: 'Actions',
            type: 'actions',
            width: 100,

        },
    ];


    return (
        <>
            <Stack
                sx={{
                    boxSizing: 'border-box',
                    py: 1,
                }}
                direction='column'
            >
                <Typography variant='h6' align='left'>Project Completion</Typography>
                <Paper
                    elevation={4}
                    square={false}
                    sx={{
                        flex: 1,
                        mx: 'auto',
                        p: 1,
                        width: '100%',

                    }}
                >
                    <Box
                        sx={{

                            '& .actions': {
                                color: 'text.secondary',
                            },
                            '& .textPrimary': {
                                color: 'text.primary',
                            },
                            height: 280,
                            width: '100%',
                        }}
                    >
                        <DataGridPremium
                            hideFooter
                            rows={rows}
                            columns={columns}
                            editMode='row'
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                            slots={{
                                toolbar: EditToolbar as GridSlots['toolbar'],
                            }}
                            slotProps={{
                                toolbar: {
                                    setRowModesModel,
                                    setRows,
                                    stackRank
                                },
                            }}
                            sx={{ flex: 1 }}
                            density='compact'

                        />
                    </Box>
                </Paper>
            </Stack>
        </>
    );
};

export default ProjectCompletionStatus;

/* Resource Magic 2024 */