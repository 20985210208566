import * as React from 'react';
import { getComparator, stableSort } from '../sortUtils';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import EnhancedTableHead from './enhancedTableHead';
import EnhancedTableToolbar from './enhancedTableToolbar';
import FormControlLabel from '@mui/material/FormControlLabel';
import type { Order } from '../sortUtils';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableContextMenu from './enhancedTableContextMenu';
import type { TableContextMenuOption } from './enhancedTableContextMenu';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

export type { TableContextMenuOption };

export interface HeadCell<T> {
    disablePadding: boolean;
    id: keyof T;
    label: string;
    numeric: boolean;
    width: number;
}

export interface EnhancedTableProps<T> {
    rows: T[] | [];
    setRows: React.Dispatch<React.SetStateAction<T[]>>;
    headCells: readonly HeadCell<T>[];
    additionalContextMenuOptions?: Map<string, (index: number) => T[]>;
}

const { useMemo, useState } = React;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },

}));

export const EnhancedTable = <T extends { [key in keyof T]: string | number }>(
    props: EnhancedTableProps<T>,
): React.JSX.Element => {
    const { additionalContextMenuOptions, headCells, rows, setRows } = props;
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof T>('test' as keyof T);
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedRows, setSelectedRows] = useState<T[] | []>([]);
    const [contextMenu, setContextMenu] = useState<{
        top: number;
        left: number;
        index: number;
    } | null>(null);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked) {
            const newSelected = rows.map((row) => Object.values(row)[0] as string);
            setSelected(newSelected);
            setSelectedRows(rows);
            return;
        }
        setSelected([]);
        setSelectedRows([]);
    };

    const handleClick = (name: string): void => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setDense(event.target.checked);
    };

    const handleContextMenu =
        (event: React.MouseEvent<HTMLTableRowElement>, index: number): void => {
            event.preventDefault();
            setContextMenu(
                contextMenu === null
                    ? {
                        index,
                        left: event.clientX + 2,
                        top: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes
                    //it with Chrome 84 on Ubuntu Other native context menus
                    //might behave different. With this behavior we prevent
                    // contextmenu from the backdrop to re - locale existing context menus.
                    null,
            );
        };

    const isSelected = (name: string): boolean => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [rows, order, orderBy, page, rowsPerPage],
    );

    const onChange = (row: T, isItemSelected: boolean): void => {
        if (!isItemSelected) {
            setSelectedRows((prevRows) => [...prevRows, row]);
        } else {
            setSelectedRows((prevRows) => prevRows.filter((r) => r !== row));
        }
    };

    const processCell = (row: T): (React.JSX.Element | undefined)[] => {
        const cells: unknown[] = Object.values(row);
        return cells.map((cell, index) => {
            if (index > 0) {
                return (
                    <TableCell key={index} align={typeof cell === 'string' ? 'left' : 'right'}>
                        {cell as string | number}
                    </TableCell>
                );
            }
        });
    };

    const deleteRecord = (index: number): void => {
        setRows((prevRows) => prevRows.filter((row) => row !== rows[index]));
        setSelectedRows((prevRows) => prevRows.filter((row) => row !== rows[index]));
    };

    const deleteAllRecords = (): void => {
        setRows([]);
        setSelectedRows([]);
    };

    const options: TableContextMenuOption[] = [
        {
            action: deleteRecord,
            label: 'Delete',

        },
        {
            action: deleteAllRecords,
            label: 'Delete All',

        },
    ];

    additionalContextMenuOptions?.forEach((value, key) => {
        options.push({
            action: (index: number) => {
                setRows(value(index));
            },
            label: key,

        });
    });

    return (
        <Box sx={{ minWidth: 470 }}>
            <Paper sx={{
                mb: 2,
                minWidth: 470,
            }}>
                <React.Fragment>
                    {!!contextMenu?.top &&
                        <TableContextMenu
                            anchorPosition={{
                                left: contextMenu.left,
                                top: contextMenu.top,

                            }}
                            options={options}
                            handleClose={() => {
                                setContextMenu(null);
                            }}
                            index={contextMenu?.index}
                        />
                    }
                    <EnhancedTableToolbar
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        setRows={setRows}
                    />
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 300 }}
                            aria-labelledby='tableTitle'
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                headCells={headCells}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy as string}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(
                                        Object.values(row)[0] as string,
                                    );
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <StyledTableRow
                                            hover
                                            role='checkbox'
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={Object.values(row)[0] as string}
                                            selected={isItemSelected}
                                            onContextMenu={(event) => {
                                                handleContextMenu(event, index);
                                            }
                                            }
                                        >
                                            <TableCell padding='checkbox'>
                                                <Checkbox
                                                    color='primary'
                                                    checked={isItemSelected}
                                                    onClick={() => {
                                                        handleClick(Object.values(row)[0] as string);
                                                    }
                                                    }
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    onChange={() => {
                                                        onChange(row, isItemSelected);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component='th'
                                                id={labelId}
                                                scope='row'
                                                padding='none'
                                            >
                                                {Object.values(row)[0] as string}
                                            </TableCell>
                                            {processCell(row)}
                                        </StyledTableRow>
                                    );
                                })}
                                {emptyRows > 0 &&
                                    <StyledTableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </StyledTableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </React.Fragment>
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label='Dense padding'
            />
        </Box>
    );
};

export default EnhancedTable;