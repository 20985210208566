import * as React from 'react';
import type { EmployeeType, ProjectType } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import type { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid2';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material/Select';
import SettingDialog from '../components/settingDialog';
import type { SettingsType } from '../components/settingDialog';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import type { UnknownAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { fetchPostProjects } from '../slices/projectsSlice';
import { selectAllEmployees } from '../slices/employeesSlice';
import { selectAllProjectStatus } from '../slices/projectStatusSlice';
import { selectAllProjectTypes } from '../slices/projectTypesSlice';

const { useState } = React;

const ADD_NEW = 'Add New...';

interface SelectBoxProps {
    items: string[]; // list of options
    name: string; // Title
    setting: string; // name of the setting
    setSettings: React.Dispatch<React.SetStateAction<string>>;
    setOpenAddOptions: (value: React.SetStateAction<boolean>) => void;
    initSelection: string | string[];
    setParamSelection?: React.Dispatch<React.SetStateAction<ProjectType>>;
}

// Select Box for single properties
export const SelectBox = (props: SelectBoxProps): React.JSX.Element => {
    const {
        initSelection,
        items,
        name,
        setting,
        setOpenAddOptions,
        setParamSelection,
        setSettings,
    } = props;
    const [selection, setSelection] = useState<string>(initSelection as string);

    const handleChange = (event: SelectChangeEvent<string | string[]>): void => {
        setOpenAddOptions(false);
        const newValue: string = event.target.value as string;
        if (newValue === ADD_NEW) {
            setSettings(setting);
            setOpenAddOptions(true);
            return;
        } else {
            setParamSelection?.((oldObject) => {
                const obj = Object.defineProperty({}, setting, {
                    enumerable: true,
                    value: newValue,
                });
                return {
                    ...oldObject,
                    ...obj,
                };
            });
            setSelection(newValue);
        }
    };

    const options = [...items, ADD_NEW].map((i) =>
        <MenuItem key={i} value={i}>
            {i}
        </MenuItem>
    );

    return (
        <Box sx={{ minWidth: '100%' }}>
            <FormControl fullWidth variant='standard' size='small'>
                <InputLabel id='demo-simple-select-label'>{name}</InputLabel>
                <Tooltip title={selection}>
                    <Select
                        defaultValue={initSelection}
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        onChange={handleChange}
                        sx={{
                            textAlign: 'left',
                            width: '100%',
                        }}
                    >
                        {options}
                    </Select>
                </Tooltip>
            </FormControl>
        </Box>
    );
};

export const ProjectDetails = ({
    project,
    projects,
}: {
    project: ProjectType;
    projects: ProjectType[];
}): React.JSX.Element => {
    const dispatch = useDispatch();
    const employees = useSelector(selectAllEmployees) ?? [];
    const projectTypes = useSelector(selectAllProjectTypes)?.map((t) => t.projectType) ?? [];
    const projectStatus = useSelector(selectAllProjectStatus)?.map((s) => s.status) ?? [];
    const [openAddOptions, setOpenAddOptions] = useState<boolean>(false);
    const [settings, setSettings] = useState<string>('');
    const [currentProject, setCurrentProject] = useState<ProjectType>(project);
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(currentProject.startDate));
    const [mainContact, setMainContact] = useState<EmployeeType | undefined>(
        employees.find((e) => e.eid === currentProject.mainContact),
    );

    const employeesOptions = employees.map((e) =>
        <MenuItem key={e.eid} value={e.eid}>
            {e.name}
        </MenuItem>
    );
    employeesOptions.push(
        <MenuItem key={-1} value={-1}>
            {'No Contact'}
        </MenuItem>,
    );

    const onSave = (): void => {
        const allProjects = projects.filter(
            (p) => p.projectName !== project.projectName && p.stackRank !== project.stackRank,
        );
        allProjects.push({
            ...currentProject,
            startDate: startDate,
        });
        dispatch(fetchPostProjects({ projects: allProjects }) as unknown as UnknownAction);
    };

    return (
        <>
            <SettingDialog
                setting={settings as SettingsType}
                show={openAddOptions}
                setOpenAddOptions={setOpenAddOptions}
            />
            <Stack
                sx={{
                    boxSizing: 'border-box',
                    py: 1,
                }}
                direction='column'
            >
                <Paper
                    elevation={4}
                    square={false}
                    sx={{
                        flex: 1,
                        mx: 'auto',
                        p: 1,
                        width: '100%',
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Stack spacing={2} direction='row' margin={1}>
                            <Button
                                sx={{ margin: 1 }}
                                variant='outlined'
                                size='small'
                                onClick={onSave}
                            >
                                Save
                            </Button>
                            <Button sx={{ margin: 1 }} variant='outlined' size='small'>
                                Cancel
                            </Button>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={currentProject.isActive}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                setCurrentProject((prevProject) => ({
                                                    ...prevProject,
                                                    isActive: event.target.checked,
                                                }));
                                            }}
                                        />
                                    }
                                    label='Is Active'
                                />
                            </FormGroup>
                        </Stack>
                        <Grid container spacing={1}>
                            <Grid size={3}>
                                <TextField
                                    id='filled-basic'
                                    label='Stack Rank'
                                    variant='standard'
                                    defaultValue={currentProject.stackRank}
                                    size='small'
                                    sx={{ width: '95%' }}
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >,
                                    ) => {
                                        setCurrentProject((prevProject) => ({
                                            ...prevProject,
                                            stackRank: event.target.value,
                                        }));
                                    }}
                                />

                            </Grid>
                            <Grid size={3}>
                                <TextField
                                    id='filled-basic'
                                    label='Project Name'
                                    variant='standard'
                                    defaultValue={currentProject.projectName}
                                    size='small'
                                    sx={{ width: '95%' }}
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >,
                                    ) => {
                                        setCurrentProject((prevProject) => ({
                                            ...prevProject,
                                            projectName: event.target.value,
                                        }));
                                    }}
                                />
                            </Grid>
                            <Grid size={3}>
                                <SelectBox
                                    initSelection={currentProject.type}
                                    setParamSelection={setCurrentProject}
                                    name='Project Type'
                                    items={projectTypes}
                                    setting={'type'}
                                    setSettings={setSettings}
                                    setOpenAddOptions={setOpenAddOptions}
                                />
                            </Grid>
                            <Grid size={3}>
                                <SelectBox
                                    initSelection={currentProject.status}
                                    setParamSelection={setCurrentProject}
                                    name='Project Status'
                                    items={projectStatus}
                                    setting={'status'}
                                    setSettings={setSettings}
                                    setOpenAddOptions={setOpenAddOptions}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid size={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']} >
                                        <DatePicker
                                            label='Start Date'
                                            value={startDate}
                                            onChange={(newStartDate) => {
                                                setStartDate(newStartDate);
                                            }}
                                            slotProps={{ textField: { size: 'small' } }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid size={3}>
                                <TextField
                                    id='standard-number'
                                    type='number'
                                    label='Duration'
                                    size='small'
                                    sx={{ width: '95%' }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant='standard'
                                    defaultValue={Number(currentProject.duration)}
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >,
                                    ) => {
                                        setCurrentProject((prevProject) => ({
                                            ...prevProject,
                                            duration: Number(event.target.value),
                                        }));
                                    }}
                                />
                            </Grid>
                            <Grid size={3}>
                                <TextField
                                    id='standard-number'
                                    type='number'
                                    label='Progress'
                                    size='small'
                                    sx={{ width: '95%' }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant='standard'
                                    defaultValue={currentProject.progress}
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >,
                                    ) => {
                                        setCurrentProject((prevProject) => ({
                                            ...prevProject,
                                            progress: Number(event.target.value),
                                        }));
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid size={12}>
                                <TextField
                                    id='filled-basic'
                                    label='Description'
                                    variant='standard'
                                    value={currentProject.description}
                                    size='small'
                                    sx={{ width: '100%' }}
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement | HTMLTextAreaElement
                                        >,
                                    ) => {
                                        setCurrentProject((prevProject) => ({
                                            ...prevProject,
                                            description: event.target.value,
                                        }));
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid size={3}>
                                <FormControl fullWidth variant='standard' size='small'>
                                    <InputLabel id='demo-simple-select-label'>
                                        {'Main Contact'}
                                    </InputLabel>
                                    <Tooltip title='Main Contact'>
                                        <Select
                                            defaultValue={
                                                mainContact
                                                    ? String(mainContact.eid)
                                                    : 'No contact'
                                            }
                                            labelId='demo-simple-select-label'
                                            id='demo-simple-select'
                                            sx={{
                                                textAlign: 'left',
                                                width: '98%',
                                            }}
                                            size='small'
                                            onChange={(
                                                event: SelectChangeEvent<string | string[]>,
                                            ) => {
                                                const eid = Number(event.target?.value);
                                                if (eid) {
                                                    const newContact = employees.find(
                                                        (contact) => contact.eid === eid,
                                                    );
                                                    setMainContact(newContact);
                                                    setCurrentProject((prevProject) => ({
                                                        ...prevProject,
                                                        mainContact: eid,
                                                    }));
                                                }
                                            }}
                                        >
                                            {employeesOptions}
                                        </Select>
                                    </Tooltip>
                                </FormControl>
                            </Grid>
                            {/* Read only */}
                            <Grid size={3}>
                                <TextField
                                    id='outlined-disabled'
                                    label='Title'
                                    variant='standard'
                                    value={mainContact?.jobTitle ?? ''}
                                    size='small'
                                    sx={{ width: '95%' }}
                                />
                            </Grid>
                            <Grid size={3}>
                                <TextField
                                    id='outlined-disabled'
                                    label='Phone'
                                    variant='standard'
                                    value={mainContact?.phone ?? ''}
                                    size='small'
                                    sx={{ width: '95%' }}
                                />
                            </Grid>
                            <Grid size={3}>
                                <TextField
                                    id='outlined-disabled'
                                    label='Email'
                                    variant='standard'
                                    value={mainContact?.email ?? ''}
                                    size='small'
                                    sx={{ width: '95%' }}
                                />
                            </Grid>
                        </Grid>

                    </Box>
                </Paper>
            </Stack>
        </>
    );
};

export default ProjectDetails;

/* Resource Magic 2024 */