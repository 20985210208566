import '../../../style.css';
import * as React from 'react';
import ProjectStatus from './projectStatus';
import ProjectTypes from './projectTypes';

export const ProjectSettings = (): React.JSX.Element => {
    return (
        <div className='grid_test'>
            <h3 className='header3'>Project Types</h3>
            <ProjectTypes />
            <br />
            <h3 className='header3'>Project Status</h3>
            <ProjectStatus />
        </div>
    );
};

export default ProjectSettings;

/* Resource Magic 2024 */