import '../../style.css';
import * as React from 'react';
import SkillCategories from './skillCategories';
import SkillLevels from './skillLevels';
import Skills from './skills';

export const SkillSettings = (): React.JSX.Element => {
    return (
        <div className='grid_test'>
            <h3 className='header3'>Skills</h3>
            <Skills />
            <br />
            <h3 className='header3'>Skill Levels</h3>
            <SkillLevels />
            <br />
            <h3 className='header3'>Skill Categories</h3>
            <SkillCategories />
        </div>
    );
};

export default SkillSettings;
