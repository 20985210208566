import * as React from 'react';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    GridToolbar,
    GridToolbarContainer,
    useGridApiContext,
} from '@mui/x-data-grid-premium';
import type { EmployeeSkillsType, Store } from '../../../types';
import type {
    GridActionsCellItemProps,
    GridColDef,
    GridEventListener,
    GridRenderEditCellParams,
    GridRowId,
    GridRowModel,
    GridRowModesModel,
    GridSlots
} from '@mui/x-data-grid-premium';
import {
    deleteEmployeeSkill,
    fetchGetEmployeeSkillLevels,
    fetchPostEmployeeSkillLevels,
    selectAllEmployeeSkills,
    addEmployeeSkill as setRows,
    updateEmployeeSkill,
} from '../../slices/employeeSkillsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import Select from '@mui/material/Select';
import SettingDialog from '../../components/settingDialog';
import type { SettingsType } from '../../components/settingDialog';
import type { UnknownAction } from '@reduxjs/toolkit';
import { randomId } from '@mui/x-data-grid-generator';
import { selectAllSkillCategories } from '../../slices/skillCategoriesSlice';
import { selectAllSkillLevels } from '../../slices/skillLevelsSlice';
import { selectAllSkills } from '../../slices/skillsSlice';

interface EmployeeSkillsProps {
    eid: number;
}

const { useLayoutEffect, useState } = React;

interface EditToolbarProps {
    setRows: (payload: {
        id: string;
        eid: number;
    }) => {
        payload: unknown;
        type: string;
    };
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
    eid: number;
}

export const EmployeeSkills = (props: EmployeeSkillsProps): React.JSX.Element => {
    const { eid } = props;
    const [openAddOptions, setOpenAddOptions] = useState<boolean>(false);
    const [settings, setSettings] = useState<string>('');

    const dispatch = useDispatch();
    const r = useSelector(selectAllEmployeeSkills);
    const rows = r.find((e) => e.eid === eid)?.skills ?? [];
    const fetchStatus = useSelector((state: Store) => state.employeeSkills.status);
    const isEdit = useRef(false);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const skills = useSelector(selectAllSkills).map((s) => s.skill);
    const skillsLevels = useSelector(selectAllSkillLevels).map((s) => s.level);
    const skillsCategories = useSelector(selectAllSkillCategories).map((s) => s.category);

    useEffect(() => {
        if (fetchStatus === 'idle') {
            dispatch(fetchGetEmployeeSkillLevels(eid) as unknown as UnknownAction);
        }
    }, []);

    useEffect(() => {
        if (!isEdit.current) {
            return;
        }
        isEdit.current = false;
        console.log(rows);
        const saveRows: { employeeSkills: EmployeeSkillsType } = {
            employeeSkills: {
                eid,
                skills: rows.map((row) => ({
                    category: row.category,
                    description: row.description,
                    id: row.id,
                    level: row.level,
                    skill: row.skill,
                })),
            },
        };

        dispatch(fetchPostEmployeeSkillLevels(saveRows) as unknown as UnknownAction);
    }, [rows]);

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel): void => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => (): void => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.Edit },
        });
    };

    const handleSaveClick = (id: GridRowId) => (): void => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
        });
        isEdit.current = true;
    };

    const handleDeleteClick = (id: GridRowId) => (): void => {
        dispatch(deleteEmployeeSkill(id));
        isEdit.current = true;
    };

    const handleCancelClick = (id: GridRowId) => (): void => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {
                ignoreModifications: true,
                mode: GridRowModes.View,
            },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.isNew) {
            dispatch(deleteEmployeeSkill(id));
        }
    };

    const processRowUpdate = (newRow: GridRowModel): {
        isNew: boolean;
    } => {
        dispatch(updateEmployeeSkill({
            eid,
            newRow,
        }));
        return {
            ...newRow,
            isNew: false
        };
    };

    const SelectionCell = (sProps: GridRenderEditCellParams): React.JSX.Element => {
        const { id, value, field, hasFocus } = sProps;
        let select: string[] = [];
        switch (field) {
            case 'skill':
                select = skills;
                break;
            case 'level':
                select = skillsLevels;
                break;
            case 'category':
                select = skillsCategories;
                break;
            default:
                select = [];
                break;
        }

        const apiRef = useGridApiContext();
        const ref = useRef();

        useLayoutEffect(() => {
            if (hasFocus) {
                (ref.current as any)?.focus();
            }
        }, [hasFocus]);

        const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            setOpenAddOptions(false);
            const newValue = event.target.value;
            if (newValue === 'Add New...') {
                setSettings(field);
                setOpenAddOptions(true);
                return;
            }
            apiRef.current.setEditCellValue({
                id,
                field,
                value: newValue
            });
        };

        const items = [
            <MenuItem key='Choose one…' value='Choose one…' disabled={true}>
                Choose one…
            </MenuItem>,
        ];
        items.push(
            ...[...select, 'Add New...'].map((d) =>
                <MenuItem key={d} value={d}>
                    {d}
                </MenuItem>
            ),
        );

        return (
            <Select
                defaultValue={'Choose one…'}
                ref={ref}
                style={{ width: 180 }}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={value ? value : 'Choose one…'}
                label='Domain'
                onChange={handleValueChange as any}
            >
                {items}
            </Select>
        );
    };

    const EditToolbar = (eProps: EditToolbarProps): React.JSX.Element => {
        const eDispatch = useDispatch();
        const handleClick = (): void => {
            const id = randomId();
            eDispatch(eProps.setRows({
                eid: eProps.eid,
                id,
            }));
            eProps.setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: {
                    fieldToFocus: 'name',
                    mode: GridRowModes.Edit,
                },
            }));
        };

        return (
            <GridToolbarContainer>
                <GridToolbar />
                <Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
                    Add record
                </Button>
            </GridToolbarContainer>
        );
    };

    const columns: GridColDef[] = [
        {
            editable: true,
            field: 'skill',
            headerName: 'Skill',
            renderEditCell: (params) => <SelectionCell {...params} />,
            type: 'string',
            width: 180,
        },
        {
            editable: true,
            field: 'level',
            headerName: 'Level',
            renderEditCell: (params) => <SelectionCell {...params} />,
            type: 'string',
            width: 180,
        },
        {
            editable: true,
            field: 'category',
            headerName: 'Category',
            renderEditCell: (params) => <SelectionCell {...params} />,
            type: 'string',
            width: 180,
        },
        {
            align: 'left',
            editable: true,
            field: 'description',
            headerAlign: 'left',
            headerName: 'Description',
            width: 280,
        },
        {
            cellClassName: 'actions',
            field: 'actions',

            getActions: ({ id }): readonly React.ReactElement<GridActionsCellItemProps>[] => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key='0'
                            icon={<SaveIcon />}
                            label='Save'
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key='1'
                            icon={<CancelIcon />}
                            label='Cancel'
                            className='textPrimary'
                            onClick={handleCancelClick(id)}
                            color='inherit'
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key='2'
                        icon={<EditIcon />}
                        label='Edit'
                        className='textPrimary'
                        onClick={handleEditClick(id)}
                        color='inherit'
                    />,
                    <GridActionsCellItem
                        key='3'
                        icon={<DeleteIcon />}
                        label='Delete'
                        onClick={handleDeleteClick(id)}
                        color='inherit'
                    />,
                ];
            },
            headerName: 'Actions',
            type: 'actions',
            width: 90,
        },
    ];

    return (
        <>
            <SettingDialog
                setting={settings as SettingsType}
                show={openAddOptions}
                setOpenAddOptions={setOpenAddOptions}
            />
            <Box
                sx={{
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                    height: 400,
                    width: '100%',
                }}
            >
                <DataGridPremium
                    pagination
                    autoPageSize
                    columns={columns}
                    rows={rows}
                    editMode='row'
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                        toolbar: EditToolbar as GridSlots['toolbar'],
                    }}
                    slotProps={{
                        toolbar: {
                            eid,
                            setRowModesModel,
                            setRows,
                        },
                    }}
                    sx={{ flex: 1 }}
                    density='compact'
                />
            </Box>
        </>
    );
};

export default EmployeeSkills;
