import type { FetchType, ProjectTypesType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';


export const fetchGetProjectTypes = createAsyncThunk<ProjectTypesType[]>(
    'project_settings/getProjectTypes',
    async () => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',
        };
        const response = await fetch(`${SERVER_IP}/settings/project_settings/project_types`, init);
        const data = (await response.json()) as ProjectTypesType[];
        return data;
    },
);

export const fetchPostProjectTypes = createAsyncThunk(
    'project_settings/postProjectTypes',
    async (saveRows: { project_types: ProjectTypesType[] }) => {
        const init: RequestInit = {
            body: JSON.stringify(saveRows),
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'POST',
        };

        const data = await fetch(`${SERVER_IP}/settings/project_settings/project_types`, init);
        const json = (await data.json()) as Promise<unknown>;
        console.log(json);
    },
);

const initialState: FetchType<ProjectTypesType[]> = {
    error: null,
    fetchType: [] as ProjectTypesType[],
    status: 'idle',
};

export const projectTypesSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetProjectTypes.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetProjectTypes.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetProjectTypes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPostProjectTypes.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostProjectTypes.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchPostProjectTypes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'projectTypes',
    reducers: {
        addProjectType: (state, action) => {
            const { id } = action.payload;
            state.fetchType.push({
                description: '',
                id,
                isNew: true,
                projectType: '',
            });
        },
        deleteProjectType: (state, action) => {
            state.fetchType = state.fetchType?.filter((row) => row.id !== action.payload);
        },
        updateProjectType: (state, action) => {
            const projectType = action.payload as ProjectTypesType;
            const updatedRow: ProjectTypesType = {
                ...projectType,
                isNew: false
            };
            state.fetchType = state.fetchType.map((row) =>
                row.id === projectType.id ? updatedRow : row,
            );
        },
    },
});

export const selectAllProjectTypes = (state: Store): ProjectTypesType[] =>
    state.projectTypes.fetchType;
export const { deleteProjectType, updateProjectType, addProjectType } = projectTypesSlice.actions;
export default projectTypesSlice.reducer;
