import * as React from 'react';
import { Dayjs } from 'dayjs';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';


export type HighlightedBackgroundColor = 'Block' | 'Sick Day' | 'Vacation' | 'Weekend' | 'Project';

export type HighlightedDayProp = {
    blockType: HighlightedBackgroundColor,
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
    day: Dayjs,
    isSelected: boolean,
    id: 'simple-popover' | undefined,
    [x: string]: unknown,
}

const HighlightedDayBlock = styled(PickersDay)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: 'blue',
        color: theme.palette.primary.contrastText,
    },
}));

const HighlightedDaySickDay = styled(PickersDay)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: 'red',
        color: theme.palette.primary.contrastText,
    },
}));

const HighlightedDayVacation = styled(PickersDay)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: 'green',
        color: theme.palette.primary.contrastText,
    },
}));

const HighlightedWeekend = styled(PickersDay)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: 'lightgrey',
        color: theme.palette.primary.contrastText,
    },
}));

const HighlightedDayProject = styled(PickersDay)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: 'yellow',
        color: theme.palette.primary.contrastText,
    },
}));

const HighlightedDayDefault = styled(PickersDay)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: 'white',
        color: theme.palette.primary.contrastText,
    },
}));

export const HighlightedDay = (props: HighlightedDayProp) => {
    const { blockType, day, handleClick, id, isSelected, ...other } = props;
    const properties = {
        onClick: handleClick,
        onDaySelect: () => {
            throw new Error('Function not implemented.');
        },
        showDaysOutsideCurrentMonth: false,
        outsideCurrentMonth: true,
        isFirstVisibleCell: false,
        isLastVisibleCell: false,
        ...other,
        day: day,
        selected: isSelected,
        disableMargin: false,
    };

    switch (blockType) {
        case 'Block':
            return (
                <HighlightedDayBlock
                    aria-describedby={id}
                    {...properties}
                />
            );
        case 'Sick Day':
            return (
                <HighlightedDaySickDay
                    aria-describedby={id}
                    {...properties}
                />
            );
        case 'Vacation':
            return (
                <HighlightedDayVacation
                    aria-describedby={id}
                    {...properties}
                />
            );
        case 'Weekend':
            return (
                <HighlightedWeekend
                    aria-describedby={id}
                    {...properties}
                    selected
                />
            );
        case 'Project':
            return (
                <HighlightedDayProject
                    aria-describedby={id}
                    {...properties}
                />
            );
        default:
            return (
                <HighlightedDayDefault
                    aria-describedby={id}
                    {...properties}
                />
            );

    }
};

export default HighlightedDay;