import * as React from 'react';

import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import AddTaskTwoToneIcon from '@mui/icons-material/AddTaskTwoTone';
import Checkbox from '@mui/material/Checkbox';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import Diversity3TwoToneIcon from '@mui/icons-material/Diversity3TwoTone';
import Employees from './employees/employees';
import EmployeeSettings from './employees/employeeSettings/employeeSettings';
import FormControlLabel from '@mui/material/FormControlLabel';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import MiscellaneousServicesTwoToneIcon from '@mui/icons-material/MiscellaneousServicesTwoTone';
import Miscellaneous from './miscellaneous/miscellaneous';
import Projects from './projects/projects';
import ProjectSettings from './projects/projectSettings/projectSettings';
import SkillSettings from './skills/skillSettings';
import type { UnknownAction } from '@reduxjs/toolkit';
import { fetchGetContactTypes } from './slices/contactTypesSlice';
import { fetchGetDomainExpertise } from './slices/employeeExpertiseSlice';
import { fetchGetEmployeeRoles } from './slices/employeeRolesSlice';
import { fetchGetEmployees } from './slices/employeesSlice';
import { fetchGetAllEmployeesSkillLevels } from './slices/employeeSkillsSlice';
import { fetchGetJobTitles } from './slices/employeeTitlesSlice';
import { fetchGetProjectCompletionStatus } from './slices/projectCompletionSlice';
import { fetchGetProjectRequirements } from './slices/projectRequirementsSlice';
import { fetchGetProjectStatus } from './slices/projectStatusSlice';
import { fetchGetProjectTypes } from './slices/projectTypesSlice';
import { fetchGetProjects } from './slices/projectsSlice';
import { fetchGetSkillCategories } from './slices/skillCategoriesSlice';
import { fetchGetSkillLevels } from './slices/skillLevelsSlice';
import { fetchGetSkills } from './slices/skillsSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const { useState } = React;

export const Home = (): React.JSX.Element => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchGetJobTitles() as unknown as UnknownAction);
        dispatch(fetchGetDomainExpertise() as unknown as UnknownAction);
        dispatch(fetchGetEmployeeRoles() as unknown as UnknownAction);
        dispatch(fetchGetContactTypes() as unknown as UnknownAction);
        dispatch(fetchGetProjectTypes() as unknown as UnknownAction);
        dispatch(fetchGetSkillCategories() as unknown as UnknownAction);
        dispatch(fetchGetSkillLevels() as unknown as UnknownAction);
        dispatch(fetchGetSkills() as unknown as UnknownAction);
        dispatch(fetchGetEmployees() as unknown as UnknownAction);
        dispatch(fetchGetAllEmployeesSkillLevels() as unknown as UnknownAction);
        dispatch(fetchGetProjectCompletionStatus() as unknown as UnknownAction);
        dispatch(fetchGetProjectRequirements() as unknown as UnknownAction);
        dispatch(fetchGetProjectStatus() as unknown as UnknownAction);
        dispatch(fetchGetProjects() as unknown as UnknownAction);
    }, []);

    const [showProjects, setShowProjects] = useState<boolean>(false);
    const [showEmployees, setShowEmployees] = useState<boolean>(false);
    const [showEmployeeSettings, setShowEmployeeSettings] = useState<boolean>(false);
    const [showProjectSettings, setShowProjectSettings] = useState<boolean>(false);
    const [showSkillSettings, setSkillProjectSettings] = useState<boolean>(false);
    const [showMiscellaneousSettings, setShowMiscellaneousSettings] = useState<boolean>(false);

    return <div>
        <FormControlLabel
            control={<Checkbox
                icon={<AccountTreeOutlinedIcon />}
                checkedIcon={<AccountTreeTwoToneIcon />}
                onClick={() => {
                    setShowProjects(!showProjects);
                    setShowEmployees(false);
                    setShowEmployeeSettings(false);
                    setShowProjectSettings(false);
                    setSkillProjectSettings(false);
                    setShowMiscellaneousSettings(false);
                }}
            />}
            label="Projects"
            labelPlacement="start"
        />
        <FormControlLabel
            control={<Checkbox
                icon={<Diversity3OutlinedIcon />}
                checkedIcon={<Diversity3TwoToneIcon />}
                onClick={() => {
                    setShowEmployees(!showEmployees);
                    setShowProjects(false);
                    setShowEmployeeSettings(false);
                    setShowProjectSettings(false);
                    setSkillProjectSettings(false);
                    setShowMiscellaneousSettings(false);
                }}
            />}
            label="Employees"
            labelPlacement="start"
        />
        <FormControlLabel
            control={<Checkbox
                icon={<GroupAddOutlinedIcon />}
                checkedIcon={<GroupAddTwoToneIcon />}
                onClick={() => {
                    setShowEmployeeSettings(!showEmployeeSettings);
                    setShowProjects(false);
                    setShowEmployees(false);
                    setShowProjectSettings(false);
                    setSkillProjectSettings(false);
                    setShowMiscellaneousSettings(false);
                }}
            />}
            label="Employee Settings"
            labelPlacement="start"
        />
        <FormControlLabel
            control={<Checkbox
                icon={<DisplaySettingsOutlinedIcon />}
                checkedIcon={<DisplaySettingsTwoToneIcon />}
                onClick={() => {
                    setShowProjectSettings(!showProjectSettings);
                    setShowProjects(false);
                    setShowEmployees(false);
                    setShowEmployeeSettings(false);
                    setSkillProjectSettings(false);
                    setShowMiscellaneousSettings(false);
                }}
            />}
            label="Project Settings"
            labelPlacement="start"
        />
        <FormControlLabel
            control={<Checkbox
                icon={<AddTaskOutlinedIcon />}
                checkedIcon={<AddTaskTwoToneIcon />}
                onClick={() => {
                    setSkillProjectSettings(!showSkillSettings);
                    setShowProjects(false);
                    setShowEmployees(false);
                    setShowEmployeeSettings(false);
                    setShowProjectSettings(false);
                    setShowMiscellaneousSettings(false);
                }}
            />}
            label="Skill Settings"
            labelPlacement="start"
        />
        <FormControlLabel
            control={<Checkbox
                icon={<MiscellaneousServicesOutlinedIcon />}
                checkedIcon={<MiscellaneousServicesTwoToneIcon />}
                onClick={() => {
                    setShowMiscellaneousSettings(!showMiscellaneousSettings);
                    setShowProjects(false);
                    setShowEmployees(false);
                    setShowEmployeeSettings(false);
                    setShowProjectSettings(false);
                    setSkillProjectSettings(false);
                }}
            />}
            label="Miscellaneous Settings"
            labelPlacement="start"
        />

        {showProjects && <Projects />}
        {showEmployees && <Employees />}
        {showEmployeeSettings && <EmployeeSettings />}
        {showProjectSettings && <ProjectSettings />}
        {showSkillSettings && <SkillSettings />}
        {showMiscellaneousSettings && <Miscellaneous />}
    </div>;
};

export default Home;

/* Resource Magic 2024 */