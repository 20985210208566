import type { FetchType, ProjectStatusType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';

export const fetchGetProjectStatus = createAsyncThunk<ProjectStatusType[]>(
    'project_settings/getProjectStatus',
    async () => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',
        };
        const response = await fetch(`${SERVER_IP}/settings/project_settings/project_status`, init);
        const data = (await response.json()) as ProjectStatusType[];
        return data;
    },
);

export const fetchPostProjectStatus = createAsyncThunk(
    'project_settings/postProjectStatus',
    async (saveRows: { project_status: ProjectStatusType[] }) => {
        const init: RequestInit = {
            body: JSON.stringify(saveRows),
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'POST',
        };
        const data = await fetch(`${SERVER_IP}/settings/project_settings/project_status`, init);
        const json = (await data.json()) as Promise<unknown>;
        console.log(json);
    },
);

const initialState: FetchType<ProjectStatusType[]> = {
    error: null,
    fetchType: [] as ProjectStatusType[],
    status: 'idle',
};

export const projectStatusSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetProjectStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetProjectStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetProjectStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPostProjectStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostProjectStatus.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchPostProjectStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'projectStatus',
    reducers: {
        addProjectStatus: (state, action) => {
            const { id } = action.payload;
            state.fetchType.push({
                description: '',
                id,
                isNew: true,
                status: '',
            });
        },
        deleteProjectStatus: (state, action) => {
            state.fetchType = state.fetchType?.filter((row) => row.id !== action.payload);
        },
        updateProjectStatus: (state, action) => {
            const status = action.payload as ProjectStatusType;
            const updatedRow = {
                ...status,
                isNew: false
            };
            state.fetchType = state.fetchType.map((row) =>
                row.id === status.id ? updatedRow : row,
            );
        },
    },

});

export const selectAllProjectStatus = (state: Store): ProjectStatusType[] =>
    state.projectStatus.fetchType;
export const { deleteProjectStatus, updateProjectStatus, addProjectStatus } =
    projectStatusSlice.actions;
export default projectStatusSlice.reducer;
