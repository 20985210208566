import '../../../style.css';
import * as React from 'react';
import DomainExpertise from './employeeDomainExpertise';
import EmployeeRoles from './employeeRoles';
import JobTitles from './employeeJobTitles';


export const EmployeeSettings = (): React.JSX.Element => {
    return (
        <div className='grid_test'>
            <h3 className='header3'>Employee Roles</h3>
            <EmployeeRoles />
            <br />
            <h3 className='header3'>Employee Domain Expertise</h3>
            <DomainExpertise />
            <br />
            <h3 className='header3'>Job Titles</h3>
            <JobTitles />
        </div>
    );
};

export default EmployeeSettings;
