import * as React from 'react';
import Paper from '@mui/material/Paper';
import ProjectCompletionStatus from './projectCompletion';
import ProjectDetails from './projectDetails';
import ProjectRequirements from './projectRequirements';
import type { ProjectType } from '../../types';
import Stack from '@mui/material/Stack';

export const DetailPanelContent = ({
    project,
    projects,
}: {
    project: ProjectType;
    projects: ProjectType[];
}): React.JSX.Element => {
    return (
        <Stack
            sx={{
                boxSizing: 'border-box',
                height: '100%',
                py: 2,
            }}
            direction='column'
        >
            <Paper
                sx={{
                    flex: 1,
                    mx: 'auto',
                    p: 1,
                    width: '95%',
                }}
            >
                <Stack direction='column' spacing={1} sx={{ height: 1 }}>
                    <ProjectDetails project={project} projects={projects} />
                    <ProjectRequirements stackRank={project.stackRank} />
                    <ProjectCompletionStatus stackRank={project.stackRank} />
                </Stack>
            </Paper>
        </Stack>
    );
};

export default DetailPanelContent;

/* Resource Magic 2024 */