import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';

export interface TableContextMenuOption {
    label: string;
    action: (index: number) => void;
}

export interface TableContextMenuProps {
    options: TableContextMenuOption[];
    handleClose: (index?: number) => void;
    anchorPosition: {
        top: number;
        left: number;
    } | undefined;
    index: number;
}

export const TableContextMenu = (props: TableContextMenuProps): React.JSX.Element => {
    const { options, handleClose, anchorPosition, index } = props;

    return (
        <Menu
            id='long-menu'
            anchorReference='anchorPosition'
            anchorPosition={anchorPosition} // Use the saved anchorPosition instead of anchorEl
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'top',
            }}
            transformOrigin={{
                horizontal: 'left',
                vertical: 'top',
            }}
            keepMounted
            open={!!anchorPosition}
            onClose={() => {
                handleClose();
            }}
        >
            {options.map((option) =>
                <MenuItem
                    onClick={(event) => {
                        option.action(index);
                        handleClose();
                        event.stopPropagation();
                    }}
                    key={option.label}
                    selected={option.label === 'Pyxis'}
                >
                    {option.label}
                </MenuItem>
            )}
        </Menu>
    );
};

export default TableContextMenu;
