import * as React from 'react';
import type { EmployeeScheduleType, FetchType, HighLightType, Store } from '../../../../types';
import {
    addEmployeeSchedule,
    fetchGetEmployeeSchedule,
    fetchPostEmployeeSchedule,
    selectAllEmployeesSchedule,
    updateEmployeeSchedule,
} from '../../../slices/employeeScheduleSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box } from '@mui/material';
import { Button } from '@mui/material';
import { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import type { UnknownAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { LeftMonthHeader, RightMonthHeader } from './scheduleHeader';
import ScheduleDay from './scheduleDay';
import Stack from '@mui/material/Stack';

const { useEffect, useState } = React;

export const EmployeeSchedule = (props: { eid: number }): React.JSX.Element => {

    const now = new Date;
    const nextDate = dayjs(new Date(now.setMonth(now.getMonth() + 1)));

    const [leftMonth, setLeftMonth] = useState<Dayjs | null>(dayjs(new Date));
    const [rightMonth, setRightMonth] = useState<Dayjs | null>(nextDate);

    const { eid } = props;
    const dispatch = useDispatch();

    // Existing schedule for eid
    const eidSchedule: FetchType<EmployeeScheduleType> | undefined = useSelector(
        selectAllEmployeesSchedule,
    ).find((s) => s.fetchType.eid === eid);
    if (!eidSchedule) {
        dispatch(addEmployeeSchedule(eid));
    }
    const initSchedule = eidSchedule ? eidSchedule.fetchType.daySlots : [];

    const fetchStatus = useSelector((state: Store) => state.employeeSchedule).find(
        (s) => s.fetchType.eid === eid,
    )?.status;

    const [highlightedDays, setHighlightedDays] = useState<HighLightType[]>(initSchedule);
    const today = dayjs();

    const onSaveClick = (): void => {
        dispatch(updateEmployeeSchedule({
            daySlots: highlightedDays,
            eid,
        }));
        dispatch(
            fetchPostEmployeeSchedule({
                daySlots: highlightedDays,
                eid,
            }) as unknown as UnknownAction,
        );
    };

    useEffect(() => {
        if (fetchStatus === 'idle') {
            dispatch(fetchGetEmployeeSchedule(eid) as unknown as UnknownAction);
        }
    }, []);

    useEffect(() => {
        if (fetchStatus === 'succeeded') {
            setHighlightedDays(initSchedule);
        }
    }, [fetchStatus]);

    return (
        <Box >
            <Stack spacing={1} direction='column'>
                <Stack spacing={1} direction='row'>
                    <Button sx={{ margin: 1 }} variant='outlined' size='small' onClick={onSaveClick}>
                        Save
                    </Button>

                    <Button sx={{ margin: 1 }} variant='outlined' size='small'>
                        Cancel
                    </Button>
                </Stack>
                <DemoContainer components={['LocalizationProvider']}>
                    <Box sx={{
                        alignItems: 'flex-end',
                        display: 'flex',
                    }}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                        >
                            <Stack spacing={4} direction='row'>
                                <StaticDatePicker
                                    value={leftMonth}
                                    onChange={(newValue) => setLeftMonth(newValue)}
                                    onMonthChange={(month) => setRightMonth(month.add(1, 'month'))}
                                    displayWeekNumber
                                    showDaysOutsideCurrentMonth={false}
                                    fixedWeekNumber={6}
                                    defaultValue={today}
                                    slots={{
                                        day: ScheduleDay as any,
                                        calendarHeader: LeftMonthHeader
                                    }}
                                    slotProps={{
                                        day: {
                                            highlightedDays,
                                            setHighlightedDays
                                        } as any,
                                        actionBar: {
                                            actions: [],
                                        },
                                        toolbar: { hidden: true }
                                    }}

                                />
                                <StaticDatePicker
                                    value={rightMonth}
                                    onChange={(newValue) => setRightMonth(newValue)}
                                    onMonthChange={(month) => setLeftMonth(month.add(-1, 'month'))}
                                    displayWeekNumber
                                    showDaysOutsideCurrentMonth={false}
                                    fixedWeekNumber={6}
                                    slots={{
                                        day: ScheduleDay as any,
                                        calendarHeader: RightMonthHeader
                                    }}
                                    slotProps={{
                                        day: {
                                            highlightedDays,
                                            setHighlightedDays
                                        } as any,
                                        actionBar: {
                                            actions: [],
                                        },
                                        toolbar: { hidden: true }
                                    }}

                                />
                            </Stack>
                        </LocalizationProvider>
                    </Box>
                </DemoContainer>
            </Stack>
        </Box>
    );
};

export default EmployeeSchedule;
