import type { EmployeeContactType, FetchType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';

export const fetchGetEmployeeContacts = createAsyncThunk<EmployeeContactType, number>(
    'employee_settings/getContacts',
    async (eid: number) => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',
        };
        const response = await fetch(`${SERVER_IP}/employees/contacts/${eid}`, init);
        const data = (await response.json()) as EmployeeContactType;
        return data;
    },
);

export const fetchPostEmployeeContacts = createAsyncThunk(
    'employee_settings/postContacts',
    async (contacts: EmployeeContactType) => {
        const init: RequestInit = {
            body: JSON.stringify(contacts),
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'POST',
        };
        const data = await fetch(`${SERVER_IP}/employees/contacts`, init);
        console.log(await data.json());
    },
);

const initialState: FetchType<EmployeeContactType>[] = [];

export const employeeContactsSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetEmployeeContacts.pending, (state, action) => {
                state.forEach((s) => {
                    if (s.fetchType.eid === action.meta.arg) {
                        s.status = 'loading';
                    }
                });
            })
            .addCase(fetchGetEmployeeContacts.fulfilled, (state, action) => {
                state.forEach((s) => {
                    if (s.fetchType.eid === action.meta.arg) {
                        s.status = 'succeeded';
                        s.error = null;
                        s.fetchType = action.payload;
                    }
                });
            })
            .addCase(fetchGetEmployeeContacts.rejected, (state, action) => {
                state = state.filter((c) => c.fetchType.eid !== action.meta.arg);
                state.push({
                    error: action.error.message,
                    fetchType: {
                        address: [],
                        eid: action.meta.arg,
                        emails: [],
                        phones: [],
                    },
                    status: 'failed',
                });
            })
            .addCase(fetchPostEmployeeContacts.pending, (state, action) => {
                state.forEach((s) => {
                    if (s.fetchType.eid === action.meta.arg.eid) {
                        s.status = 'loading';
                    }
                });
            })
            .addCase(fetchPostEmployeeContacts.fulfilled, (state, action) => {
                state.forEach((s) => {
                    if (s.fetchType.eid === action.meta.arg.eid) {
                        s.status = 'succeeded';
                    }
                });
            })
            .addCase(fetchPostEmployeeContacts.rejected, (state, action) => {
                state.forEach((s) => {
                    if (s.fetchType.eid === action.meta.arg.eid) {
                        s.status = 'failed';
                        s.error = action.error.message;
                    }
                });
            });
    },
    initialState,
    name: 'employeeContacts',
    reducers: {
        addEmployeeContact: (state, action) => {
            state.push({
                error: null,
                fetchType: { eid: action.payload as number },
                status: 'idle',
            });
        },
        deleteEmployeeContact: (state, action) => {
            const eid = (action.payload as EmployeeContactType).eid;
            state = state.filter((c) => c.fetchType.eid !== eid);
        },
        updateEmployeeContact: (state, action) => {
            const contacts = action.payload as EmployeeContactType;
            state.forEach((c) => {
                if (c.fetchType.eid === contacts.eid) {
                    c.fetchType.phones = contacts.phones;
                    c.fetchType.emails = contacts.emails;
                    c.fetchType.address = contacts.address;
                }
            });
        },
    },

});

export const selectAllEmployeeContacts =
    (state: Store): FetchType<EmployeeContactType>[] => state.employeeContacts;
export const { deleteEmployeeContact, updateEmployeeContact, addEmployeeContact } =
    employeeContactsSlice.actions;
export default employeeContactsSlice.reducer;
