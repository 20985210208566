import type { EmployeeScheduleType, FetchType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';

export const fetchGetEmployeeSchedule = createAsyncThunk<EmployeeScheduleType, number>(
    'employee/getEmployeeSchedule',
    async (eid: number) => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',
        };
        const response = await fetch(`${SERVER_IP}/employees/employee_schedule/${eid}`, init);
        const data = (await response.json()) as EmployeeScheduleType;
        return data;
    },
);

export const fetchPostEmployeeSchedule = createAsyncThunk(
    'employee/postEmployeeSchedule',
    async (schedule: EmployeeScheduleType) => {
        const init: RequestInit = {
            body: JSON.stringify(schedule),
            headers: new Headers({ 'content-type': 'application/json' }),
            method: 'POST',

        };

        const data = await fetch(`${SERVER_IP}/employees/employee_schedule`, init);
        console.log(await data.json());
    },
);

const initialState: FetchType<EmployeeScheduleType>[] = [];

export const employeeScheduleSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetEmployeeSchedule.pending, (state, action) => {
                state.forEach((s) => {
                    if (s.fetchType.eid === action.meta.arg) {
                        s.status = 'loading';
                    }
                });
            })
            .addCase(fetchGetEmployeeSchedule.fulfilled, (state, action) => {
                state.forEach((s) => {
                    if (s.fetchType.eid === action.meta.arg) {
                        s.status = 'succeeded';
                        s.error = null;
                        s.fetchType = action.payload;
                    }
                });
            })
            .addCase(fetchGetEmployeeSchedule.rejected, (state, action) => {
                state = state.filter((c) => c.fetchType.eid !== action.meta.arg);
                state.push({
                    error: action.error.message,
                    fetchType: {
                        daySlots: [],
                        eid: action.meta.arg,
                    },
                    status: 'failed',

                });
            })
            .addCase(fetchPostEmployeeSchedule.pending, (state, action) => {
                state.forEach((s) => {
                    if (s.fetchType.eid === action.meta.arg.eid) {
                        s.status = 'loading';
                    }
                });
            })
            .addCase(fetchPostEmployeeSchedule.fulfilled, (state, action) => {
                state.forEach((s) => {
                    if (s.fetchType.eid === action.meta.arg.eid) {
                        s.status = 'succeeded';
                    }
                });
            })
            .addCase(fetchPostEmployeeSchedule.rejected, (state, action) => {
                state.forEach((s) => {
                    if (s.fetchType.eid === action.meta.arg.eid) {
                        s.status = 'failed';
                        s.error = action.error.message;
                    }
                });
            });
    },
    initialState,
    name: 'employeeSchedule',
    reducers: {
        addEmployeeSchedule: (state, action) => {
            const eid = action.payload as number;
            state.push({
                error: null,
                fetchType: {
                    daySlots: [],
                    eid,
                },
                status: 'idle',
            });
        },
        deleteEmployeeSchedule: (state, action) => {
            const schedule = action.payload as EmployeeScheduleType;
            state = state.filter((c) => c.fetchType.eid !== schedule.eid);
        },
        updateEmployeeSchedule: (state, action) => {
            const schedule = action.payload as EmployeeScheduleType;
            const eid = schedule.eid;
            state.forEach((c) => {
                if (c.fetchType.eid === eid) {
                    c.fetchType.daySlots = schedule.daySlots;
                }
            });
        },
    },

});

export const selectAllEmployeesSchedule = (state: Store): FetchType<EmployeeScheduleType>[] =>
    state.employeeSchedule ?? [];
export const { addEmployeeSchedule, deleteEmployeeSchedule, updateEmployeeSchedule } =
    employeeScheduleSlice.actions;
export default employeeScheduleSlice.reducer;
