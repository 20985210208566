import * as React from 'react';
import type {
    EmailType,
    EmployeeContactType,
    EmployeeType,
    FetchType,
    PhoneType,
    Store,
} from '../../types';
import {
    addEmployeeContact,
    fetchGetEmployeeContacts,
    fetchPostEmployeeContacts,
    selectAllEmployeeContacts,
    updateEmployeeContact,
} from '../slices/employeeContactSlice';
import { fetchPostEmployee, updateEmployee } from '../slices/employeesSlice';
import { useDispatch, useSelector } from 'react-redux';
import Address from '../contacts/address';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import EmailControl from '../contacts/emailContacts';
import EmployeeSchedule from './employeeSettings/schedule/employeeSchedule';
import EmployeeSkills from './employeeSettings/employeeSkills';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid2';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import PhoneControl from '../contacts/phoneContacts';
import Select from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material/Select';
import SettingDialog from '../components/settingDialog';
import type { SettingsType } from '../components/settingDialog';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import type { Theme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import type { UnknownAction } from '@reduxjs/toolkit';
import { randomId } from '@mui/x-data-grid-generator';
import { selectAllDomainExpertise } from '../slices/employeeExpertiseSlice';
import { selectAllEmployeeRoles } from '../slices/employeeRolesSlice';
import { selectAllJobTitles } from '../slices/employeeTitlesSlice';
import { styled } from '@mui/material/styles';

const { useEffect, useState } = React;

const ADD_NEW = 'Add New...';
const SPACING = 1;

export const Item = styled(Paper)(({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    color: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(SPACING),
    textAlign: 'left',

}));

interface SelectBoxProps {
    items: string[]; // list of options
    name: string; // Title
    setting: string; // name of the setting
    setSettings: React.Dispatch<React.SetStateAction<string>>;
    setOpenAddOptions: (value: React.SetStateAction<boolean>) => void;
    initSelection: string | string[];
    setParamSelection?: React.Dispatch<React.SetStateAction<EmployeeType>>;
}

export const SelectBox = (props: SelectBoxProps): React.JSX.Element => {
    const {
        items,
        name,
        setting,
        setOpenAddOptions,
        setSettings,
        initSelection,
        setParamSelection,
    } = props;
    const [selection, setSelection] = useState<string>(initSelection as string);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setOpenAddOptions(false);
        const newValue: string = event.target.value;
        if (newValue === ADD_NEW) {
            setSettings(setting);
            setOpenAddOptions(true);
            return;
        } else {
            setParamSelection?.((oldObject) => {
                const obj = Object.defineProperty({}, setting, {
                    enumerable: true,
                    value: newValue,

                });
                return {
                    ...oldObject,
                    ...obj
                };
            });
            setSelection(newValue);
        }
    };

    const options = [...items, ADD_NEW].map((i) =>
        <MenuItem key={i} value={i}>
            {i}
        </MenuItem>
    );

    return (
        <Box sx={{ minWidth: '100%' }}>
            <FormControl fullWidth variant='standard' size='small'>
                <InputLabel id='demo-simple-select-label'>{name}</InputLabel>
                <Tooltip title={selection}>
                    <Select
                        defaultValue={initSelection}
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        onChange={handleChange as any}
                        sx={{
                            textAlign: 'left',
                            width: '98%',

                        }}
                    >
                        {options}
                    </Select>
                </Tooltip>
            </FormControl>
        </Box>
    );
};

export const SelectBoxMulti = (props: SelectBoxProps): React.JSX.Element => {
    const {
        items,
        name,
        setting,
        setOpenAddOptions,
        setSettings,
        initSelection,
        setParamSelection,
    } = props;
    const [selection, setSelection] = useState<string[]>(initSelection as []);

    const handleChange = (event: SelectChangeEvent<typeof selection>): void => {
        setOpenAddOptions(false);
        const {
            target: { value },
        } = event;

        let values = typeof value === 'string' ? value.split(',') : value;

        if (values.includes(ADD_NEW)) {
            setSettings(setting);
            setOpenAddOptions(true);
            return;
        }
        values = values.filter((v) => v !== ADD_NEW);
        setParamSelection?.((oldObject) => {
            const obj = Object.defineProperty({}, setting, {
                enumerable: true,
                value: values,
            });
            return {
                ...oldObject,
                ...obj
            };
        });
        setSelection(values);
    };

    const options = [...items, ADD_NEW].map((i) =>
        <MenuItem key={i} value={i}>
            {i}
        </MenuItem>
    );
    return (
        <Box sx={{ minWidth: '100%' }}>
            <FormControl fullWidth variant='standard' size='small'>
                <InputLabel id='demo-simple-select-label'>{name}</InputLabel>
                <Tooltip title={selection.join(', ')}>
                    <Select
                        defaultValue={selection}
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        multiple
                        value={selection}
                        onChange={handleChange as any}
                        sx={{
                            textAlign: 'left',
                            width: '98%',
                        }}
                    >
                        {options}
                    </Select>
                </Tooltip>
            </FormControl>
        </Box>
    );
};

export const EmployeesDetailPanelContent =
    ({ row: rowProp }: { row: EmployeeType }): React.JSX.Element => {
        const { eid } = rowProp;
        const dispatch = useDispatch();

        // Contacts for eid
        const initContacts: FetchType<EmployeeContactType> | undefined = useSelector(
            selectAllEmployeeContacts,
        ).find((c) => c.fetchType.eid === eid);
        if (!initContacts) {
            dispatch(addEmployeeContact(eid));
        }
        const [phoneContacts, setPhoneContacts] = useState<PhoneType[]>(
            initContacts?.fetchType?.phones ?? [],
        );
        const [emailContacts, setEmailContacts] = useState<EmailType[]>(
            initContacts?.fetchType?.emails ?? [],
        );

        const fetchStatus = useSelector((state: Store) => state.employeeContacts).find(
            (s) => s.fetchType.eid === eid,
        )?.status;

        const [openAddOptions, setOpenAddOptions] = useState<boolean>(false);
        const [settings, setSettings] = useState<string>('');
        // all available roles, domains and titles
        const roles = useSelector(selectAllEmployeeRoles).map((r) => r.employeeRole);
        const domains = useSelector(selectAllDomainExpertise).map((d) => d.employeeExpertise);
        const jobTitles = useSelector(selectAllJobTitles).map((t) => t.jobTitle);

        const [employee, setEmployee] = useState<EmployeeType>(rowProp);

        const [showContacts, setShowContacts] = useState<boolean>(false);
        const [showAddress, setShowAddress] = useState<boolean>(false);

        const [isNewContacts, setIsNewContacts] = useState<boolean>(true);
        const [primePhone, setPrimePhone] = useState<string>(
            phoneContacts.find((c) => c.priority === 'Primary')?.phone ?? '',
        );
        const [primeEmail, setPrimeEmail] = useState<string>(
            emailContacts.find((c) => c.priority === 'Primary')?.email ?? '',
        );

        const onSaveClick = (): void => {
            const newEmployee: EmployeeType = {
                domains: employee.domains,
                eid,
                email: emailContacts.find((c) => c.priority === 'Primary')?.email ?? '',
                id: randomId(),
                isActive: employee.isActive,
                jobTitle: employee.jobTitle,
                name: employee.name,
                notes: employee.notes,
                phone: phoneContacts.find((c) => c.priority === 'Primary')?.phone ?? '',
                roles: employee.roles,
            };
            dispatch(updateEmployee(newEmployee));
            dispatch(fetchPostEmployee({ employee: newEmployee }) as unknown as UnknownAction);
        };

        useEffect(() => {
            if (fetchStatus === 'idle') {
                dispatch(fetchGetEmployeeContacts(eid) as unknown as UnknownAction);
            }
        }, []);

        useEffect(() => {
            if (fetchStatus !== 'succeeded') {
                return;
            }
            let isNew = false;
            setPhoneContacts(initContacts?.fetchType?.phones ?? []);
            setEmailContacts(initContacts?.fetchType?.emails ?? []);
            const newPrimePhone =
                initContacts?.fetchType?.phones?.find((c) => c.priority === 'Primary')?.phone ?? '';
            if (newPrimePhone !== primePhone) {
                isNew = true;
                setPrimePhone(newPrimePhone);
            }
            const newPrimeEmail =
                initContacts?.fetchType?.emails?.find((c) => c.priority === 'Primary')?.email ?? '';
            if (newPrimeEmail !== primeEmail) {
                isNew = true;
                setPrimeEmail(newPrimePhone);
            }
            if (isNew) {
                onSaveClick();
            }
        }, [initContacts]);

        useEffect(() => {
            if (isNewContacts) {
                setIsNewContacts(false);
                return;
            }
            dispatch(
                updateEmployeeContact({
                    eid,
                    emails: emailContacts,
                    phones: phoneContacts,
                }),
            );
            dispatch(
                fetchPostEmployeeContacts({
                    eid,
                    emails: emailContacts,
                    phones: phoneContacts,
                }) as unknown as UnknownAction,
            );
            onSaveClick();
            //dispatch(fetchPostEmployee({ employee }) as unknown as UnknownAction);
        }, [emailContacts, phoneContacts, primePhone, primeEmail]);

        return (
            <>
                <SettingDialog
                    setting={settings as SettingsType}
                    show={openAddOptions}
                    setOpenAddOptions={setOpenAddOptions}
                />
                <Stack sx={{
                    boxSizing: 'border-box',
                    height: '100%',
                    py: 2,

                }} direction='column'>
                    <Paper
                        elevation={4}
                        square={false}
                        sx={{
                            flex: 1,
                            mx: 'auto',
                            p: 1,
                            width: '95%',
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Stack spacing={2} direction='row' margin={1}>
                                <Button
                                    sx={{ margin: 1 }}
                                    variant='outlined'
                                    size='small'
                                    onClick={onSaveClick}
                                >
                                    Save
                                </Button>
                                <Button sx={{ margin: 1 }} variant='outlined' size='small'>
                                    Cancel
                                </Button>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size='small'
                                                checked={employee.isActive}
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    setEmployee((prevEmployee) => ({
                                                        ...prevEmployee,
                                                        isActive: event.target.checked,
                                                    }));
                                                }}
                                            />
                                        }
                                        label='Is Active'
                                    />
                                </FormGroup>
                            </Stack>


                            <Grid container spacing={1}>
                                <Grid size={2}>
                                    <TextField
                                        id='filled-basic'
                                        label='EID'
                                        variant='standard'
                                        value={eid}
                                        size='small'
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid size={5}>
                                    <TextField
                                        id='filled-basic'
                                        label='Name'
                                        variant='standard'
                                        defaultValue={employee.name}
                                        size='small'
                                        sx={{ width: '100%' }}
                                        onChange={
                                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                                setEmployee((prevEmployee) => ({
                                                    ...prevEmployee,
                                                    name: event.target.value,
                                                }));
                                            }}
                                    />
                                </Grid>
                                <Grid size={5}>
                                    <SelectBox
                                        initSelection={employee.jobTitle}
                                        setParamSelection={setEmployee}
                                        name='Title'
                                        items={jobTitles}
                                        setting={'jobTitle'}
                                        setSettings={setSettings}
                                        setOpenAddOptions={setOpenAddOptions}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid size={6}>
                                    <SelectBoxMulti
                                        initSelection={employee.roles}
                                        setParamSelection={setEmployee}
                                        name='Job Role'
                                        items={roles}
                                        setting={'roles'}
                                        setSettings={setSettings}
                                        setOpenAddOptions={setOpenAddOptions}
                                    />
                                </Grid>
                                <Grid size={6}>
                                    <SelectBoxMulti
                                        initSelection={employee.domains}
                                        setParamSelection={setEmployee}
                                        name='Domains'
                                        items={domains}
                                        setting={'domains'}
                                        setSettings={setSettings}
                                        setOpenAddOptions={setOpenAddOptions}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid size={12}>
                                    <TextField
                                        id='filled-basic'
                                        label='Notes'
                                        variant='standard'
                                        defaultValue={employee.notes}
                                        size='small'
                                        sx={{ width: '100%' }}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>) => {
                                            setEmployee((prevEmployee) => ({
                                                ...prevEmployee,
                                                notes: event.target.value,
                                            }));
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid size={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showContacts}
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        setShowContacts(event.target.checked);
                                                    }}
                                                />
                                            }
                                            label='Show Contacts'
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid size={6}>
                                    {showContacts &&
                                        <PhoneControl
                                            phoneContacts={phoneContacts}
                                            setPhoneContacts={setPhoneContacts}
                                        />
                                    }
                                </Grid>
                                <Grid size={6}>
                                    {showContacts &&
                                        <EmailControl
                                            emailContacts={emailContacts}
                                            setEmailContacts={setEmailContacts}
                                        />
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid size={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showAddress}
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        setShowAddress(event.target.checked);
                                                    }}
                                                />
                                            }
                                            label='Show Address'
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid size={12}>
                                    {showAddress && <Address />}
                                </Grid>
                            </Grid>

                            <Box height={20} />
                            <EmployeeSkills eid={eid} />
                            <EmployeeSchedule eid={eid} />
                        </Box>
                    </Paper>
                </Stack>
            </>
        );
    };

export default EmployeesDetailPanelContent;
