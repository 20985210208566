import type { FetchType, SkillCategoryType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';

export const fetchGetSkillCategories = createAsyncThunk<SkillCategoryType[]>(
    'skillCategories/getCategories',
    async () => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',
        };
        const response = await fetch(`${SERVER_IP}/settings/project_settings/categories`, init);
        const data = (await response.json()) as SkillCategoryType[];
        return data;
    },
);

export const fetchPostSkillCategories = createAsyncThunk(
    'skillCategories/postCategories',
    async (saveRows: { skill_categories: SkillCategoryType[] }) => {
        const init: RequestInit = {
            body: JSON.stringify(saveRows),
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'POST',
        };
        const data = await fetch(`${SERVER_IP}/settings/project_settings/categories`, init);
        const json = (await data.json()) as Promise<unknown>;
        console.log(json);
    },
);

const initialState: FetchType<SkillCategoryType[]> = {
    error: null,
    fetchType: [] as SkillCategoryType[],
    status: 'idle',
};

export const skillCategoriesSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetSkillCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetSkillCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetSkillCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPostSkillCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostSkillCategories.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchPostSkillCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'skillCategories',
    reducers: {
        addSkillCategory: (state, action) => {
            const { id } = action.payload;
            state.fetchType.push({
                category: '',
                description: '',
                id,
                isNew: true,
                subCategories: [],
            });
        },
        addSkillSubcategory: (state, action) => {
            const category = state.fetchType.find((c) => c.id === action.payload.category.id);
            if (!category) {
                return;
            }
            const index = state.fetchType.indexOf(category);
            if (!state.fetchType[index].subCategories) {
                state.fetchType[index].subCategories = [];
            }
            state.fetchType[index].subCategories.push({
                description: '',
                id: action.payload.subcategoryId,
                isNew: true,
                subcategory: '',
            });
        },
        deleteSkillCategory: (state, action) => {
            state.fetchType = state.fetchType.filter((row) => row.id !== action.payload);
        },
        deleteSkillSubcategory: (state, action) => {
            const category = state.fetchType.find((c) => c.id === action.payload.category.id);
            if (!category) {
                return;
            }
            const index = state.fetchType.indexOf(category);
            state.fetchType[index].subCategories = category.subCategories?.filter(
                (c) => c.id !== action.payload.subcategoryId,
            );
        },
        updateSkillCategory: (state, action) => {
            const skillCategory = action.payload as SkillCategoryType;
            const updatedRow = {
                ...skillCategory,
                isNew: false
            };
            state.fetchType = state.fetchType.map((row) =>
                row.id === skillCategory.id ? updatedRow : row,
            );
        },
        updateSkillSubcategory: (state, action) => {
            const category = state.fetchType.find((c) => c.id === action.payload.categoryId);
            if (!category) {
                return;
            }
            const index = state.fetchType.indexOf(category);
            if (!state.fetchType[index].subCategories) {
                state.fetchType[index].subCategories = [];
            }
            const updatedRow = {
                ...action.payload.subcategory,
                isNew: false
            };
            state.fetchType[index].subCategories = category.subCategories?.map((row) =>
                row.id === action.payload.subcategory.id ? updatedRow : row,
            );
        },
    },

});

export const selectAllSkillCategories = (state: Store): SkillCategoryType[] =>
    state.skillCategories.fetchType;

export const {
    addSkillCategory,
    deleteSkillCategory,
    updateSkillCategory,
    addSkillSubcategory,
    deleteSkillSubcategory,
    updateSkillSubcategory,
} = skillCategoriesSlice.actions;
export default skillCategoriesSlice.reducer;
