import type { FetchType, JobTitleType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';

export const fetchGetJobTitles = createAsyncThunk<JobTitleType[]>(
    'project_settings/getEmployeeTitles',
    async () => {
        const init: RequestInit = {
            headers: new Headers({
                'content-type': 'application/json',

            }),
            mode: 'no-cors',
            method: 'GET',
        };
        const response = await fetch(
            `${SERVER_IP}/settings/project_settings/employee_titles`,
            init,
        );
        const data = (await response.json()) as JobTitleType[];
        return data;
    },
);

export const fetchPostJobTitles = createAsyncThunk(
    'project_settings/postEmployeeTitles',
    async (saveRows: { employee_titles: JobTitleType[] }) => {
        const init: RequestInit = {
            body: JSON.stringify(saveRows),
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'POST',
        };
        const data = await fetch(`${SERVER_IP}/settings/project_settings/employee_titles`, init);
        console.log(await data.json());
    },
);

const initialState: FetchType<JobTitleType[]> = {
    error: null,
    fetchType: [] as JobTitleType[],
    status: 'idle',
};

export const JobTitlesSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetJobTitles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetJobTitles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetJobTitles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPostJobTitles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostJobTitles.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchPostJobTitles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'jobTitles',
    reducers: {
        addJobTitle: (state, action) => {
            const { id } = action.payload;
            state.fetchType.push({
                description: '',
                id,
                isNew: true,
                jobTitle: '',

            });
        },
        deleteJobTitle: (state, action) => {
            state.fetchType = state.fetchType?.filter((row) => row.id !== action.payload);
        },
        updateJobTitle: (state, action) => {
            const updatedRow = {
                ...action.payload,
                isNew: false
            } as JobTitleType;
            state.fetchType = state.fetchType.map((row) =>
                row.id === (action.payload as JobTitleType).id ? updatedRow : row,
            );
        },
    },
});

export const selectAllJobTitles = (state: Store): JobTitleType[] => state.jobTitles.fetchType;
export const { deleteJobTitle, updateJobTitle, addJobTitle } = JobTitlesSlice.actions;
export default JobTitlesSlice.reducer;
