import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { states } from './states';

export const StateSelector = (): React.JSX.Element => {
    return (
        <Autocomplete
            id='state-select'
            size='small'
            sx={{ width: 220 }}
            options={states}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) =>
                <Box component='li' sx={{
                    '& > img': {
                        flexShrink: 0,
                        mr: 2,
                    }
                }} {...props}>
                    <img
                        src={`https://flagcdn.com/20x15/us-${option.code.toLowerCase()}.png`}
                        srcSet={
                            `https://flagcdn.com/40x30/us-${option.code.toLowerCase()}.png 2x, https://flagcdn.com/48x36/us-${option.code.toLowerCase()}.png 3x`}
                        alt={`${option.code}`}
                    />
                    {option.label} ({option.code})
                </Box>
            }
            renderInput={(params) =>
                <TextField
                    style={{ width: 220 }}
                    {...params}
                    label='Choose a state'
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            }
        />
    );
};

export default StateSelector;
