import type {
    CompletionStatusForProjectType,
    FetchType,
    ProjectCompletionStatusType,
    Store
} from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    SERVER_IP
} from '../../types';

export const fetchGetProjectCompletionStatus = createAsyncThunk<ProjectCompletionStatusType[]>(
    'project/getCompletionStatus',
    async (): Promise<ProjectCompletionStatusType[]> => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',
        };
        const response = await fetch(`${SERVER_IP}/project/completion_status`, init);
        const data = (await response.json()) as ProjectCompletionStatusType[];
        return data;
    },
);

export const fetchPostProjectCompletionStatus = createAsyncThunk<
    unknown,
    { completionStatus: ProjectCompletionStatusType }
>(
    'project/postCompletionStatus',
    async (saveCompletion: { completionStatus: ProjectCompletionStatusType }) => {
        const init: RequestInit = {
            body: JSON.stringify(saveCompletion),
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'POST',
        };
        const data = await fetch(`${SERVER_IP}/project/completion_status`, init);
        const json = (await data.json()) as Promise<unknown>;
        console.log(json);
    });

const initialState: FetchType<ProjectCompletionStatusType[]> = {
    error: null,
    fetchType: [] as ProjectCompletionStatusType[],
    status: 'idle',
};

export const projectCompletionStatusSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetProjectCompletionStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetProjectCompletionStatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetProjectCompletionStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPostProjectCompletionStatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostProjectCompletionStatus.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchPostProjectCompletionStatus.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'projectCompletionStatus',
    reducers: {
        addCompletionStatus: (state, action) => {
            const { id, stackRank } = action.payload as {
                stackRank: string;
                id: string;
            };
            const addRow: CompletionStatusForProjectType = {
                domain: '',
                duration: 0,
                eid: 0,
                id,
                isNew: true,
                level: '',
                //  name: '',
                role: '',
                skill: '',
            };

            if (!state.fetchType?.length) {
                state.fetchType = [{
                    completionStatus: [addRow],
                    stackRank,
                }];
            } else {
                const newState = state.fetchType.map((f) => {
                    if (f.stackRank !== stackRank) {
                        return f;
                    } else {
                        f.completionStatus.push(addRow);
                        return f;
                    }
                });

                if (!newState.find((req) => req.stackRank === stackRank)) {
                    newState.push({
                        completionStatus: [addRow],
                        stackRank: stackRank,
                    });
                }
                state.fetchType = newState;
            }
        },
        deleteCompletionStatus: (state, action) => {
            const payload = action.payload as {
                stackRank: string;
                id: string;
            };
            const fRec = state.fetchType.filter((rec) => {
                if (rec.stackRank !== payload.stackRank) {
                    return rec;
                } else {
                    const a = rec.completionStatus.filter((r) => r.id !== payload.id);
                    rec.completionStatus = a;
                    return rec;
                }
            });
            state.fetchType = fRec;
        },
        updateCompletionStatus: (state, action) => {
            const payload = action.payload as {
                newRow: CompletionStatusForProjectType;
                stackRank: string;
            };
            const updatedRow = {
                ...payload.newRow,
                isNew: false
            };
            const a = state.fetchType.map((f) => {
                if (f.stackRank !== payload.stackRank) {
                    return f;
                } else {
                    f.completionStatus = f.completionStatus.map((r) =>
                        r.id === payload.newRow.id ? updatedRow : r,
                    );
                    return f;
                }
            });
            console.log(a);
            //state.fetchType = a as any;
        },
    },

});

export const selectAllProjectCompletions = (state: Store): ProjectCompletionStatusType[] =>
    state.projectCompletionStatus.fetchType;
export const { addCompletionStatus, deleteCompletionStatus, updateCompletionStatus } =
    projectCompletionStatusSlice.actions;
export default projectCompletionStatusSlice.reducer;
