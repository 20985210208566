import '../../style.css';
import * as React from 'react';
import {
    DataGridPremium,
    GridRowEditStopReasons
} from '@mui/x-data-grid-premium';
import type {
    DataGridPremiumProps,
    GridEventListener,
    GridRowModel,
    GridRowModesModel,
    GridSlots
} from '@mui/x-data-grid-premium';
import type { EmployeeType, FetchStatusType, Store } from '../../types';
import {
    fetchGetEmployees,
    selectAllEmployees,
    addEmployee as setRows,
    updateEmployee,
} from '../slices/employeesSlice';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import EditToolbar from '../components/editToolbar';
import EmployeeColumns from './employeesColumns';
import EmployeesDetailPanelContent from './employeesDetailPanel';
import SettingDialog from '../components/settingDialog';
import type { SettingsType } from '../components/settingDialog';
import type { UnknownAction } from '@reduxjs/toolkit';

const { useEffect, useState, useCallback } = React;

const DETAIL_PANEL_HEIGHT = 1200;

export const Employees = (): React.JSX.Element => {
    const dispatch = useDispatch();
    const getDetailPanelHeight = useCallback(() => DETAIL_PANEL_HEIGHT, []);
    const fetchStatus: FetchStatusType = useSelector((state: Store) => state.employees.status);
    const rows: EmployeeType[] = useSelector(selectAllEmployees) ?? [];
    const [rowModesModel, setRowsModesModel] = useState<GridRowModesModel>({});
    const [openAddOptions, setOpenAddOptions] = useState<boolean>(false);
    const [settings] = useState<string>('');

    useEffect(() => {
        if (fetchStatus === 'idle') {
            dispatch(fetchGetEmployees() as unknown as UnknownAction);
        }
    }, []);

    const getDetailPanelContent = useCallback<
        NonNullable<DataGridPremiumProps['getDetailPanelContent']>
    >(({ row }) => <EmployeesDetailPanelContent row={row} />, []);

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel): void => {
        setRowsModesModel(newRowModesModel);
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const processRowUpdate = (newRow: GridRowModel): {
        isNew: boolean;
    } => {
        const updatedRow = {
            ...newRow,
            isNew: false
        };
        dispatch(updateEmployee(newRow));
        return updatedRow;
    };

    return (
        <>
            <SettingDialog
                setting={settings as SettingsType}
                show={openAddOptions}
                setOpenAddOptions={setOpenAddOptions}
            />
            <Box sx={{
                height: 1200,
                width: '100%',
            }}>
                <DataGridPremium
                    pagination
                    autoPageSize
                    columns={EmployeeColumns}
                    rows={rows}
                    editMode='row'
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    getDetailPanelHeight={getDetailPanelHeight}
                    getDetailPanelContent={getDetailPanelContent}
                    slots={{
                        toolbar: EditToolbar as GridSlots['toolbar'],
                    }}
                    slotProps={{
                        toolbar: {
                            setRowModesModel: setRowsModesModel,
                            setRows,

                        },
                    }}
                    sx={{ flex: 1 }}
                    density='compact'
                />
            </Box>
        </>
    );
};

export default Employees;
