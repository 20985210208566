import * as React from 'react';
import HighlightedDay, { HighlightedDayProp, HighlightedBackgroundColor } from './scheduleStyles';
import DayMenu, { DayMenuType } from './scheduleDayMenu';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import type { EmployeeScheduleType, FetchType, HighLightType, Store } from '../../../../types';
import dayjs from 'dayjs';
import { Box } from '@mui/material';

const SUNDAY = 0;
const SATURDAY = 6;

interface DaysType {
    [x: string]: unknown;
    highlightedDays?: HighLightType[] | undefined;
    day: dayjs.Dayjs;
    setHighlightedDays: React.Dispatch<React.SetStateAction<HighLightType[]>>;
}

const Projects = (props: { project?: HighLightType }): React.JSX.Element => {
    const { project } = props;
    return (
        <>
            {project?.projects?.map((projectName) => {
                return (
                    <>
                        {projectName}
                        <br />
                    </>
                );
            })}
            {project?.blockReason}
        </>
    );
};

const ScheduleDay = (props: DaysType): React.JSX.Element => {
    const { highlightedDays = [], day, setHighlightedDays, ...other } = props;
    const days = highlightedDays.map((h) => h.day);
    const isSelected = days.includes(day.format('YYYY-MM-DD'));
    const blockType: HighlightedBackgroundColor = highlightedDays.find((d) => d.day === day.format('YYYY-MM-DD'))?.blockReason as HighlightedBackgroundColor;
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const project: HighLightType | undefined = highlightedDays.find(
        (high) => high.day === day.format('YYYY-MM-DD'),
    );

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id: 'simple-popover' | undefined = open ? 'simple-popover' : undefined;

    const handleContextMenu = (event: React.MouseEvent): void => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on
                // Ubuntu.Other native context menus might behave different.With this
                // behavior we prevent contextmenu from the backdrop to relocate existing
                //context menus.
                null,
        );
    };

    if (day.day() === SUNDAY || day.day() === SATURDAY) {
        const props: HighlightedDayProp = {
            blockType: 'Weekend',
            id,
            handleClick,
            day,
            isSelected,
            ...other
        }
        return (
            <HighlightedDay {...props} />

        );
    }
    const handleCloseMenu = (): void => {
        setContextMenu(null);
    };

    const handleMenu = (reason: string): void => {
        setHighlightedDays((prevDays) => {
            const fDay: string = day.format('YYYY-MM-DD');
            const dDay = prevDays.find((d) => d.day === fDay);
            if (!dDay) {
                if (reason === 'Unblock') {
                    return [...prevDays];
                }
                return [...prevDays, {
                    blockReason: reason,
                    day: fDay,
                }];
            } else if (!dDay.projects && reason === 'Unblock') {
                return [...prevDays.filter((d) => d.day !== fDay)];
            } else if (dDay.projects) {
                return [...prevDays];
            } else {
                return [
                    ...prevDays.filter((d) => d.day !== fDay),
                    {
                        blockReason: reason,
                        day: fDay,
                    },
                ];
            }
        });
    };

    const props1: HighlightedDayProp = {
        blockType: blockType,
        id,
        handleClick,
        day,
        isSelected,
        ...other
    };

    const props2: DayMenuType = {
        contextMenu,
        handleMenu,
        handleClose,
        handleCloseMenu,
    };

    return (
        <Box
            onContextMenu={handleContextMenu}
            style={{ cursor: 'context-menu' }}
            onMouseLeave={() => {
                setContextMenu(null);
            }}
            onClick={() => {
                setContextMenu(null);
            }}
        >
            <HighlightedDay {...props1} />
            <DayMenu {...props2} />

            {isSelected &&
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                >
                    <Typography sx={{ p: 1 }}>
                        <Projects project={project} />
                    </Typography>
                </Popover>
            }
        </Box>
    );
};

export default ScheduleDay;