import * as React from 'react';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes
} from '@mui/x-data-grid-premium';
import type { DomainExpertiseType, Store } from '../../../types';
import type {
    GridActionsCellItemProps,
    GridColDef,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowModesModel,
    GridSlots
} from '@mui/x-data-grid-premium';
import {
    deleteDomainExpertise,
    fetchGetDomainExpertise,
    fetchPostDomainExpertise,
    selectAllDomainExpertise,
    addDomainExpertise as setRows,
    updateDomainExpertise,
} from '../../slices/employeeExpertiseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import EditToolbar from '../../components/editToolbar';
import SaveIcon from '@mui/icons-material/Save';
import type { UnknownAction } from '@reduxjs/toolkit';

export const DomainExpertise = (): React.JSX.Element => {
    const dispatch = useDispatch();
    const rows = useSelector(selectAllDomainExpertise);
    const fetchStatus = useSelector((state: Store) => state.employeeExpertise.status);
    const isEdit = useRef(false);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    useEffect(() => {
        if (fetchStatus === 'idle') {
            dispatch(fetchGetDomainExpertise() as unknown as UnknownAction);
        }
    }, []);

    useEffect(() => {
        if (!isEdit.current) {
            return;
        }
        isEdit.current = false;
        const saveRows: { employee_expertise: DomainExpertiseType[] } = {
            employee_expertise: rows.map((row) => ({
                description: row.description,
                employeeExpertise: row.employeeExpertise,
                id: row.id,
            })),
        };

        dispatch(fetchPostDomainExpertise(saveRows) as unknown as UnknownAction);
    }, [rows]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => (): void => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.Edit },
        });
    };

    const handleSaveClick = (id: GridRowId) => (): void => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
        });
        isEdit.current = true;
    };

    const handleDeleteClick = (id: GridRowId) => (): void => {
        dispatch(deleteDomainExpertise(id));
        isEdit.current = true;
    };

    const handleCancelClick = (id: GridRowId) => (): void => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {
                ignoreModifications: true,
                mode: GridRowModes.View,
            },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.isNew) {
            dispatch(deleteDomainExpertise(id));
        }
    };

    const processRowUpdate = (newRow: GridRowModel): {
        isNew: boolean;
    } => {
        dispatch(updateDomainExpertise(newRow));
        return {
            ...newRow,
            isNew: false
        };
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel): void => {
        setRowModesModel(newRowModesModel);
    };

    const columns: GridColDef[] = [
        {
            align: 'left',
            editable: true,
            field: 'employeeExpertise',
            headerName: 'Domain Expertise',
            width: 170,
        },
        {
            align: 'left',
            editable: true,
            field: 'description',
            headerAlign: 'left',
            headerName: 'Description',
            width: 280,
        },
        {
            cellClassName: 'actions',
            field: 'actions',
            getActions: ({ id }): React.ReactElement<GridActionsCellItemProps>[] => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key='0'
                            icon={<SaveIcon />}
                            label='Save'
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key='1'
                            icon={<CancelIcon />}
                            label='Cancel'
                            className='textPrimary'
                            onClick={handleCancelClick(id)}
                            color='inherit'
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key='2'
                        icon={<EditIcon />}
                        label='Edit'
                        className='textPrimary'
                        onClick={handleEditClick(id)}
                        color='inherit'
                    />,
                    <GridActionsCellItem
                        key='3'
                        icon={<DeleteIcon />}
                        label='Delete'
                        onClick={handleDeleteClick(id)}
                        color='inherit'
                    />,
                ];
            },
            headerName: 'Actions',
            type: 'actions',
            width: 90,

        },
    ];

    return (
        <Box
            sx={{
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                height: 400,
                width: '100%',
            }}
        >
            <DataGridPremium
                pagination
                autoPageSize
                rows={rows}
                columns={columns}
                editMode='row'
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar as GridSlots['toolbar'],
                }}
                slotProps={{
                    toolbar: {
                        setRowModesModel,
                        setRows,
                    },
                }}
                sx={{ flex: 1 }}
                density='compact'
            />
        </Box>
    );
};

export default DomainExpertise;
