import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { countries } from './countries';

export const CountrySelector = () => {
    return (
        <Autocomplete
            id='country-select'
            size='small'
            style={{ width: 400 }}
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => 
                <Box component='li' sx={{ '& > img': { mr: 2,
                    flexShrink: 0 } }} {...props}>
                    <img
                        loading='lazy'
                        width='20'
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=''
                    />
                    {option.label} ({option.code}) +{option.phone}
                </Box>
            }
            renderInput={(params) => 
                <TextField
                    style={{ width: 450 }}
                    {...params}
                    label='Choose a country'
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            }
        />
    );
};

export default CountrySelector;
