import type { EmployeeSkillsType, FetchType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';

interface SkillRow {
    id: string;
    skill: string;
    level: string;
    category: string;
    description: string;
    isNew: boolean;
}
export const fetchGetAllEmployeesSkillLevels = createAsyncThunk<EmployeeSkillsType[]>(
    'employee/getAllEmployeeSkills',
    async () => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),
            method: 'GET',

        };
        const response = await fetch(`${SERVER_IP}/employee/employee_all_skills`, init);
        const data = (await response.json()) as EmployeeSkillsType[];
        return data;
    },
);

export const fetchGetEmployeeSkillLevels = createAsyncThunk<EmployeeSkillsType, number>(
    'employee/getEmployeeSkills',
    async (eid: number) => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',
        };
        const response = await fetch(`${SERVER_IP}/employee/employee_skills/${eid}`, init);
        const data = (await response.json()) as EmployeeSkillsType;
        return data;
    },
);

export const fetchPostEmployeeSkillLevels = createAsyncThunk(
    'employee/postEmployeeSkills',
    async (saveRows: { employeeSkills: EmployeeSkillsType }) => {
        const init: RequestInit = {
            body: JSON.stringify(saveRows),
            headers: new Headers({ 'content-type': 'application/json' }),
            method: 'POST',
        };
        const data = await fetch(`${SERVER_IP}/employee/employee_skills`, init);
        console.log(await data.json());
    },
);

const initialState: FetchType<EmployeeSkillsType[]> = {
    error: null,
    fetchType: [] as EmployeeSkillsType[],
    status: 'idle',
};

export const employeeSkillsSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetAllEmployeesSkillLevels.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetAllEmployeesSkillLevels.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetAllEmployeesSkillLevels.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(fetchGetEmployeeSkillLevels.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEmployeeSkillLevels.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { eid, skills } = action.payload;
                const filteredState = state.fetchType.filter((s) => s.eid !== eid);
                filteredState.push({
                    eid,
                    skills
                });
                state.fetchType = filteredState;
            })
            .addCase(fetchGetEmployeeSkillLevels.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(fetchPostEmployeeSkillLevels.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostEmployeeSkillLevels.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchPostEmployeeSkillLevels.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'employeeSkills',
    reducers: {
        addEmployeeSkill: (state, action) => {
            const { eid, id } = action.payload as {
                eid: number;
                id: string;
            };
            const newSkill: SkillRow = {
                category: '',
                description: '',
                id,
                isNew: true,
                level: '',
                skill: '',
            };
            let eidSkills = state.fetchType.find((s) => s.eid === eid);
            if (!eidSkills) {
                eidSkills = {
                    eid,
                    skills: [newSkill],
                };
                state.fetchType.push();
            }
            else {
                eidSkills.skills.push(newSkill);
            }
            const filteredState = state.fetchType.filter((s) => s.eid !== eid);
            filteredState.push(eidSkills);
            state.fetchType = filteredState;
        },
        deleteEmployeeSkill: (state, action) => {
            const { id } = action.payload;
            state.fetchType = state.fetchType?.filter(
                (s) => s.skills = s.skills.filter((r) => r.id !== id),
            );
        },
        updateEmployeeSkill: (state, action) => {
            const { eid, newRow } = action.payload as {
                eid: number;
                newRow: SkillRow;
            };
            const updatedRow = {
                ...newRow,
                isNew: false
            };

            state.fetchType = state.fetchType.map((e) =>
                e.eid !== eid
                    ? e
                    : {
                        eid,
                        skills: e.skills.map((s) => s.id === updatedRow.id ? updatedRow : s),
                    },
            ) as EmployeeSkillsType[];
        },
    },

});

export const selectAllEmployeeSkills = (state: Store): EmployeeSkillsType[] =>
    state.employeeSkills.fetchType;
export const { addEmployeeSkill, deleteEmployeeSkill, updateEmployeeSkill } =
    employeeSkillsSlice.actions;
export default employeeSkillsSlice.reducer;
