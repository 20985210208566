import * as React from 'react';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DomainExpertise from '../employees/employeeSettings/employeeDomainExpertise';
import EmployeeJobTitles from '../employees/employeeSettings/employeeJobTitles';
import EmployeeRoles from '../employees/employeeSettings/employeeRoles';
import ProjectStatus from '../projects/projectSettings/projectStatus';
import ProjectTypes from '../projects/projectSettings/projectTypes';
import SkillCategories from '../skills/skillCategories';
import SkillLevel from '../skills/skillLevels';
import Skills from '../skills/skills';

export type SettingsType =
    | 'employeeRole'
    | 'employeeExpertise'
    | 'skill'
    | 'skillLevel'
    | 'skillCategory';

export interface SettingDialogProps {
    setOpenAddOptions: (value: React.SetStateAction<boolean>) => void;
    show: boolean;
    setting: string;
}

export const SettingDialog = (params: SettingDialogProps): React.JSX.Element => {
    const { show, setOpenAddOptions, setting } = params;
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(show);

    // prevent function being recreated on state change
    const handleDialogClose = React.useCallback(() => {
        setDialogOpen(false);
        setOpenAddOptions(false);
    }, []);

    React.useEffect(() => {
        setDialogOpen(show);
    }, [show]);

    return (
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogContent dividers={true}>
                {(setting === 'roles' || setting === 'employeeRole') && <EmployeeRoles />}
                {(setting === 'domains' || setting === 'employeeExpertise') && <DomainExpertise />}
                {setting === 'skill' && <Skills />}
                {(setting === 'level' || setting === 'skillLevel') && <SkillLevel />}
                {(setting === 'category' || setting === 'skillCategory') && <SkillCategories />}
                {setting === 'type' && <ProjectTypes />}
                {setting === 'status' && <ProjectStatus />}
                {setting === 'jobTitle' && <EmployeeJobTitles />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SettingDialog;
