import './App.css';
import * as React from 'react';

import ResourceMagic from './main/resourceMagic';

export const App = (): React.JSX.Element =>
    <div className='App'>
        <ResourceMagic />
    </div>
    ;

export default App;
