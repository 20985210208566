import type { FetchType, SkillsType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';

export const fetchGetSkills = createAsyncThunk<SkillsType[]>(
    'project_settings/getSkills',
    async () => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',
        };
        const response = await fetch(`${SERVER_IP}/settings/project_settings/skills`, init);
        const data = (await response.json()) as SkillsType[];
        return data;
    },
);

export const fetchPostSkills = createAsyncThunk(
    'project_settings/postSkills',
    async (saveRows: { skills: SkillsType[] }) => {
        const init: RequestInit = {
            body: JSON.stringify(saveRows),
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'POST',
        };

        const data = await fetch(`${SERVER_IP}/settings/project_settings/skills`, init);
        const json = await data.json();
        console.log(json);
    },
);

const initialState: FetchType<SkillsType[]> = {
    error: null,
    fetchType: [] as SkillsType[],
    status: 'idle',
};

export const skillsSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetSkills.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetSkills.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetSkills.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPostSkills.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostSkills.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchPostSkills.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'skills',
    reducers: {
        addSkill: (state, action) => {
            const { id } = action.payload;
            state.fetchType.push({
                description: '',
                id,
                isNew: true,
                skill: '',
                version: '',
            });
        },
        deleteSkill: (state, action) => {
            state.fetchType = state.fetchType?.filter((row) => row.id !== action.payload);
        },
        updateSkill: (state, action) => {
            const updatedRow = {
                ...action.payload,
                isNew: false
            };
            state.fetchType = state.fetchType.map((row) =>
                row.id === action.payload.id ? updatedRow : row,
            );
        },
    },
});

export const selectAllSkills = (state: Store): SkillsType[] =>
    state.skills.fetchType;
export const { deleteSkill, updateSkill, addSkill } = skillsSlice.actions;
export default skillsSlice.reducer;
