import '../../style.css';
import * as React from 'react';
import ContactTypes from './contactTypes';

export const Miscellaneous = (): React.JSX.Element => {
    return (
        <div className='grid_test'>
            <h3 className='header3'>Contact Types</h3>
            <ContactTypes />
        </div>
    );
};

export default Miscellaneous;
