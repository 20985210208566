
import type { DomainExpertiseType, FetchType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';

export const fetchGetDomainExpertise = createAsyncThunk<DomainExpertiseType[]>(
    'project_settings/getEmployeeExpertise',
    async () => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',
        };
        const response = await fetch(
            `${SERVER_IP}/settings/project_settings/employee_expertise`,
            init,
        );
        const data = (await response.json()) as DomainExpertiseType[];
        return data;
    },
);

export const fetchPostDomainExpertise = createAsyncThunk(
    'project_settings/postEmployeeExpertise',
    async (saveRows: { employee_expertise: DomainExpertiseType[] }) => {
        const init: RequestInit = {
            body: JSON.stringify(saveRows),
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'POST',
        };
        const data = await fetch(`${SERVER_IP}/settings/project_settings/employee_expertise`, init);
        console.log(await data.json());
    },
);

const initialState: FetchType<DomainExpertiseType[]> = {
    error: null,
    fetchType: [] as DomainExpertiseType[],
    status: 'idle',
};

export const domainExpertiseSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetDomainExpertise.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetDomainExpertise.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetDomainExpertise.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPostDomainExpertise.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostDomainExpertise.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchPostDomainExpertise.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'employeeExpertise',
    reducers: {
        addDomainExpertise: (state, action) => {
            const { id } = action.payload;
            state.fetchType.push({
                description: '',
                employeeExpertise: '',
                id,
                isNew: true,
            });
        },
        deleteDomainExpertise: (state, action) => {
            state.fetchType = state.fetchType?.filter(
                (row) => row.id !== action.payload,
            );
        },
        updateDomainExpertise: (state, action) => {
            const expertise = action.payload as DomainExpertiseType;
            const updatedRow = {
                ...expertise,
                isNew: false
            } as DomainExpertiseType;

            state.fetchType = state.fetchType.map((row) =>
                row.id === expertise.id ? updatedRow : row,
            );
        },
    },

});

export const selectAllDomainExpertise = (state: Store): DomainExpertiseType[] =>
    state.employeeExpertise.fetchType;
export const { deleteDomainExpertise, updateDomainExpertise, addDomainExpertise } =
    domainExpertiseSlice.actions;
export default domainExpertiseSlice.reducer;
