import '../../style.css';
import * as React from 'react';
import type { PhoneContactType, PhoneType } from '../../types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EnhancedTable from '../../components/enhancedTable/enhancedTable';
import FormControl from '@mui/material/FormControl';
import type { HeadCell } from '../../components/enhancedTable/enhancedTable';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import PhoneTwoTone from '@mui/icons-material/PhoneTwoTone';
import Select from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { phoneContactType } from '../../types';
export type { HeadCell };

const { useEffect, useState } = React;

const headCells: readonly HeadCell<PhoneType>[] = [
    {
        disablePadding: false,
        id: 'contactType',
        label: 'Contact Type',
        numeric: false,
        width: 100,
    },
    {
        disablePadding: true,
        id: 'phone',
        label: 'Phone',
        numeric: false,
        width: 200,
    },
    {
        disablePadding: false,
        id: 'priority',
        label: 'Priority',
        numeric: false,
        width: 100,
    },
];

export interface PhoneControlProps {
    phoneContacts: PhoneType[];
    setPhoneContacts: React.Dispatch<React.SetStateAction<PhoneType[]>>;
}

export const PhoneControl = (props: PhoneControlProps): React.JSX.Element => {
    const { phoneContacts, setPhoneContacts } = props;
    const [rows, setRows] = useState<PhoneType[]>(phoneContacts);
    const [contactType, setContactType] = useState<PhoneContactType>('Office');
    const [phone, setPhone] = useState<string>('');

    useEffect(() => {
        setPhoneContacts(rows);
    }, [rows]);

    const handleChange = (event: SelectChangeEvent): void => {
        setContactType(event.target.value as PhoneContactType);
    };

    const onClick = (): void => {
        if (phone === '') {
            return;
        }
        const phoneRegEx = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (phoneRegEx.test(phone) === false) {
            return;
        }

        setRows((pRows) => [...pRows, {
            contactType,
            phone,
            priority: ''
        }]);
        setPhone('');
        setPhoneContacts((pRows) => [...pRows, {
            contactType,
            phone,
            priority: ''
        }]);
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const value = e.target.value;
        setPhone(value);
    };

    const setAsPrimary = (index: number) => (prevRows: PhoneType[]): PhoneType[] => {
        const newRows: PhoneType[] = prevRows.map((row) => ({
            ...row,
            priority: ''
        }));
        newRows[index] = {
            ...newRows[index],
            priority: 'Primary'
        };
        setPhoneContacts(newRows);
        return newRows;
    };

    const setAsSecondary = (index: number) => (prevRows: PhoneType[]): PhoneType[] => {
        const newRows: PhoneType[] = prevRows.map((row) => ({
            ...row,
            priority: ''
        }));
        newRows[index] = {
            ...newRows[index],
            priority: 'Secondary'
        };
        setPhoneContacts(newRows);
        return newRows;
    };

    const actions = new Map();
    actions.set('Set as Primary', setAsPrimary);
    actions.set('Set as Secondary', setAsSecondary);

    return (
        <Box
            component='form'
            sx={{
                '& .MuiTextField-root': {
                    m: 1,
                    width: '25ch'
                },
                width: 500,
            }}
            noValidate
            autoComplete='off'
        >
            <fieldset>
                <legend style={{ textAlign: 'left' }}>Phone</legend>
                <Box sx={{
                    alignItems: 'flex-end',
                    display: 'flex',
                }}>
                    <PhoneTwoTone sx={{
                        color: 'action.active',
                        mr: 0.1,
                        my: 0.5
                    }} />
                    <TextField
                        value={phone}
                        required
                        id='phone'
                        label='Phone'
                        placeholder='Enter Phone'
                        size='small'
                        margin='none'
                        variant='standard'
                        onBlur={onBlur}
                        onChange={(e) => {
                            setPhone(e.target.value);
                        }}
                    />
                    <FormControl variant='standard' sx={{
                        m: 1,
                        maxWidth: 100,
                        minWidth: 100,
                    }}>
                        <InputLabel id='demo-simple-select-standard-label'>Contact Type</InputLabel>
                        <Select
                            required
                            labelId='demo-simple-select-standard-label'
                            id='demo-simple-select-standard'
                            value={contactType}
                            onChange={handleChange}
                            label='Contact Type'
                        >
                            {phoneContactType.map((contactType1, index) => {
                                return (
                                    <MenuItem key={index} value={contactType1}>
                                        {contactType1}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Button
                        onClick={onClick}
                        variant='outlined'
                        size='small'
                        sx={{
                            color: 'action.active',
                            ml: 0.3,
                            my: 0.7
                        }}
                    >
                        Add
                    </Button>
                </Box>
                <EnhancedTable
                    additionalContextMenuOptions={actions}
                    headCells={headCells}
                    rows={rows}
                    setRows={setRows}
                />
            </fieldset>
        </Box>
    );
};

export default PhoneControl;
