import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export interface DayMenuType {
    contextMenu: { mouseX: number; mouseY: number; } | null;
    handleMenu: (reason: string) => void;
    handleClose: () => void;
    handleCloseMenu: () => void;
}



export const DayMenu = (props: DayMenuType): React.JSX.Element => {
    const { contextMenu, handleCloseMenu, handleClose, handleMenu } = props;
    return (
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference='anchorPosition'
            anchorPosition={
                contextMenu !== null
                    ? {
                        left: contextMenu.mouseX,
                        top: contextMenu.mouseY,
                    }
                    : undefined
            }
        >
            <MenuItem
                onClick={() => {
                    handleMenu('Sick Day');
                }}
            >
                Sick Day
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleMenu('Vacation');
                }}
            >
                Vacation
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleMenu('Block');
                }}
            >
                Block
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleMenu('Unblock');
                }}
            >
                Unblock
            </MenuItem>
            <MenuItem onClick={handleCloseMenu}>Exit</MenuItem>
        </Menu>)
};

export default DayMenu;