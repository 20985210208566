import * as React from 'react';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    useGridApiContext,
} from '@mui/x-data-grid-premium';
import type {
    GridActionsCellItemProps,
    GridColDef,
    GridEventListener,
    GridRenderEditCellParams,
    GridRowId,
    GridRowModel,
    GridRowModesModel,
    GridSlots
} from '@mui/x-data-grid-premium';
import type { ProjectRequirementsType, Store } from '../../types';
import {
    deleteRequirements,
    fetchGetProjectRequirements,
    fetchPostProjectRequirements,
    selectAllProjectRequirements,
    addRequirements as setRows,
    updateRequirements,
} from '../slices/projectRequirementsSlice';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import EditToolbar from '../components/editToolbar';
import type { FetchStatusType } from '../../types';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import Select from '@mui/material/Select';
import SettingDialog from '../components/settingDialog';
import type { SettingsType } from '../components/settingDialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { UnknownAction } from '@reduxjs/toolkit';
import { selectAllDomainExpertise } from '../slices/employeeExpertiseSlice';
import { selectAllEmployeeRoles } from '../slices/employeeRolesSlice';
import { selectAllSkillCategories } from '../slices/skillCategoriesSlice';
import { selectAllSkillLevels } from '../slices/skillLevelsSlice';
import { selectAllSkills } from '../slices/skillsSlice';

const { useState, useEffect, useRef } = React;

export const ProjectRequirements = (props: { stackRank: string }): React.JSX.Element => {
    const { stackRank } = props;

    const dispatch = useDispatch();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const roles: string[] = useSelector(selectAllEmployeeRoles).map((r) => r.employeeRole);
    const domains: string[] = useSelector(selectAllDomainExpertise).map((d) => d.employeeExpertise);
    const skills: string[] = useSelector(selectAllSkills).map((s) => s.skill);
    const skillLevels: string[] = useSelector(selectAllSkillLevels).map((l) => l.level);
    const skillCategory: string[] = useSelector(selectAllSkillCategories).map((c) => c.category);

    const fetchStatus: FetchStatusType = useSelector(
        (state: Store) => state.projectRequirements.status,
    );
    const [settings, setSettings] = useState<string>('');
    const [openAddOptions, setOpenAddOptions] = useState<boolean>(false);

    const rows =
        useSelector(selectAllProjectRequirements)?.find((req) => req.stackRank === stackRank)
            ?.requirements ?? [];
    const isEdit = useRef(false);

    useEffect(() => {
        if (fetchStatus === 'idle') {
            dispatch(fetchGetProjectRequirements() as unknown as UnknownAction);
        }
    }, []);

    useEffect(() => {
        if (!isEdit.current) {
            return;
        }
        isEdit.current = false;
        const saveRows: ProjectRequirementsType = {
            requirements: rows.map((row) => ({
                duration: row.duration,
                employeeExpertise: row.employeeExpertise,
                employeeRole: row.employeeRole,
                id: row.id,
                resources: row.resources,
                skill: row.skill,
                skillCategory: row.skillCategory,
                skillLevel: row.skillLevel,
                startDate: row.startDate,
            })),
            stackRank,
        };

        dispatch(
            fetchPostProjectRequirements({ requirements: saveRows }) as unknown as UnknownAction,
        );
    }, [rows]);


    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel): void => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => (): void => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.Edit },
        });
    };

    const handleSaveClick = (id: GridRowId) => (): void => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
        });
        isEdit.current = true;
    };

    const handleDeleteClick = (id: GridRowId) => (): void => {
        console.log(id);
        dispatch(deleteRequirements({
            id,
            stackRank
        }));
        isEdit.current = true;
    };

    const processRowUpdate = (newRow: GridRowModel): {
        isNew: boolean;
    } => {
        const updatedRow = {
            ...newRow,
            isNew: false
        };
        dispatch(updateRequirements({
            newRow,
            stackRank,
        }));
        return updatedRow;
    };

    const handleCancelClick = (id: GridRowId) => (): void => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {
                ignoreModifications: true,
                mode: GridRowModes.View,
            },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.isNew ?? false) {
            dispatch(deleteRequirements(id));
        }
    };

    const settingSelection = {
        employeeExpertise: domains,
        employeeRole: roles,
        skill: skills,
        skillCategory: skillCategory,
        skillLevel: skillLevels,
    };

    const SelectionCell = (props1: GridRenderEditCellParams): React.JSX.Element => {
        const { id, value, field, hasFocus } = props1;
        const select = settingSelection[field as SettingsType];

        const apiRef = useGridApiContext();
        const ref = React.useRef<JSX.Element>();

        React.useLayoutEffect(() => {
            if (hasFocus) {
                (ref.current as unknown as any)?.focus();
            }
        }, [hasFocus]);

        const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            setOpenAddOptions(false);
            const newValue = event.target.value;
            if (newValue === 'Add New...') {
                setSettings(field);
                setOpenAddOptions(true);
                return;
            }
            void apiRef.current.setEditCellValue({
                field,
                id,
                value: newValue
            });
        };

        const items = [...select ?? [], 'Add New...'].map((d) =>
            <MenuItem key={d} value={d}>
                {d}
            </MenuItem>
        );

        return (
            <Select
                placeholder='Choose one…'
                ref={ref}
                style={{ width: 150 }}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={value}
                label='Domain'
                onChange={handleValueChange as any}
            >
                {items}
            </Select>
        );
    };

    const columns: GridColDef[] = [
        {
            editable: true,
            field: 'employeeRole',
            headerName: 'Role',
            renderEditCell: (params) => <SelectionCell {...params} />,
            type: 'string',
            width: 150,
        },
        {
            editable: true,
            field: 'employeeExpertise',
            headerName: 'Domain',
            renderEditCell: (params) => <SelectionCell {...params} />,
            type: 'string',
            width: 150,
        },
        {
            editable: true,
            field: 'skill',
            headerName: 'Skills',
            renderEditCell: (params) => <SelectionCell {...params} />,
            type: 'string',
            width: 150,
        },
        {
            editable: true,
            field: 'skillLevel',
            headerName: 'Level',
            renderEditCell: (params) => <SelectionCell {...params} />,
            type: 'string',
            width: 150,
        },
        {
            editable: true,
            field: 'skillCategory',
            headerName: 'Skills Category',
            renderEditCell: (params) => <SelectionCell {...params} />,
            type: 'string',
            width: 150,
        },
        {
            editable: true,
            field: 'resources',
            headerName: '# resources',
            type: 'number',
        },
        {
            editable: true,
            field: 'startDate',
            headerName: 'Start Date',
            type: 'date',
            valueGetter: (value): Date => new Date(String(value)),
        },
        {
            editable: true,
            field: 'duration',
            headerName: 'Duration',
            type: 'number',

        },

        {
            editable: true,
            field: 'progress',
            headerName: 'Progress',
            type: 'number',
        },
        {
            cellClassName: 'actions',
            field: 'actions',
            getActions: ({ id }): React.ReactElement<GridActionsCellItemProps>[] => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key='0'
                            icon={<SaveIcon />}
                            label='Save'
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key='1'
                            icon={<CancelIcon />}
                            label='Cancel'
                            className='textPrimary'
                            onClick={handleCancelClick(id)}
                            color='inherit'
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key='2'
                        icon={<EditIcon />}
                        label='Edit'
                        className='textPrimary'
                        onClick={handleEditClick(id)}
                        color='inherit'
                    />,
                    <GridActionsCellItem
                        key='3'
                        icon={<DeleteIcon />}
                        label='Delete'
                        onClick={handleDeleteClick(id)}
                        color='inherit'
                    />,
                ];
            },
            headerName: 'Actions',
            type: 'actions',
            width: 100,

        },
    ];

    return (
        <>
            <SettingDialog
                setting={settings as SettingsType}
                show={openAddOptions}
                setOpenAddOptions={setOpenAddOptions}
            />
            <Stack
                sx={{
                    boxSizing: 'border-box',
                    py: 1,
                }}
                direction='column'
            >
                <Typography variant='h6' align='left'>Project Requirements</Typography>
                <Paper
                    elevation={4}
                    square={false}
                    sx={{
                        flex: 1,
                        mx: 'auto',
                        p: 1,
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{

                            '& .actions': {
                                color: 'text.secondary',
                            },
                            '& .textPrimary': {
                                color: 'text.primary',
                            },
                            height: 280,
                            width: '100%',
                        }}
                    >

                        <DataGridPremium
                            hideFooter
                            rows={rows}
                            columns={columns}
                            editMode='row'
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                            slots={{
                                toolbar: EditToolbar as GridSlots['toolbar'],
                            }}
                            slotProps={{
                                toolbar: {
                                    setRowModesModel,
                                    setRows,
                                    stackRank
                                },
                            }}
                            sx={{ flex: 1 }}
                            density='compact'
                        />
                    </Box>
                </Paper>
            </Stack>
        </>
    );
};

export default ProjectRequirements;

/* Resource Magic 2024 */