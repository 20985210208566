import type {
    FetchType,
    ProjectRequirementsType,
    RequirementsForProjectType,
    Store
} from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    SERVER_IP
} from '../../types';

export const fetchGetProjectRequirements = createAsyncThunk<ProjectRequirementsType[]>(
    'project/getRequirements',
    async (): Promise<ProjectRequirementsType[]> => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',

        };
        const response = await fetch(`${SERVER_IP}/project/requirements`, init);
        const data = (await response.json()) as ProjectRequirementsType[];
        return data;
    },
);

export const fetchPostProjectRequirements = createAsyncThunk<
    unknown,
    { requirements: ProjectRequirementsType }
>('project/postRequirements', async (saveReq: { requirements: ProjectRequirementsType }) => {
    const init: RequestInit = {
        body: JSON.stringify(saveReq),
        headers: new Headers({ 'content-type': 'application/json' }),

        method: 'POST',
    };
    const data = await fetch(`${SERVER_IP}/project/requirements`, init);
    const json = (await data.json()) as Promise<unknown>;
    console.log(json);
});

const initialState: FetchType<ProjectRequirementsType[]> = {
    error: null,
    fetchType: [] as ProjectRequirementsType[],
    status: 'idle',
};

export const projectRequirementsSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetProjectRequirements.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetProjectRequirements.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetProjectRequirements.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPostProjectRequirements.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostProjectRequirements.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchPostProjectRequirements.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'projectRequirements',
    reducers: {
        addRequirements: (state, action) => {
            const { id, stackRank } = action.payload as {
                stackRank: string;
                id: string;
            };
            const addRow: RequirementsForProjectType = {
                duration: 0,
                employeeExpertise: '',
                employeeRole: '',
                id,
                isNew: true,
                resources: 0,
                skill: '',
                skillCategory: '',
                skillLevel: '',
                startDate: new Date(),
            };

            if (!state.fetchType?.length) {
                state.fetchType = [{
                    requirements: [addRow],
                    stackRank,
                }];
            } else {
                const newState = state.fetchType.map((f) => {
                    if (f.stackRank !== stackRank) {
                        return f;
                    } else {
                        f.requirements.push(addRow);
                        return f;
                    }
                });

                if (!newState.find((req) => req.stackRank === stackRank)) {
                    newState.push({
                        requirements: [addRow],
                        stackRank: stackRank,
                    });
                }
                state.fetchType = newState;
            }
        },
        deleteRequirements: (state, action) => {
            const payload = action.payload as {
                stackRank: string;
                id: string;
            };
            const fRec = state.fetchType.filter((rec) => {
                if (rec.stackRank !== payload.stackRank) {
                    return rec;
                } else {
                    const a = rec.requirements.filter((r) => r.id !== payload.id);
                    rec.requirements = a;
                    return rec;
                }
            });
            state.fetchType = fRec;
        },
        updateRequirements: (state, action) => {
            const payload = action.payload as {
                newRow: RequirementsForProjectType;
                stackRank: string;
            };
            const updatedRow = {
                ...payload.newRow,
                isNew: false
            };
            const a = state.fetchType.map((f) => {
                if (f.stackRank !== payload.stackRank) {
                    return f;
                } else {
                    f.requirements = f.requirements.map((r) =>
                        r.id === payload.newRow.id ? updatedRow : r,
                    );
                    return f;
                }
            });
            console.log(a);
            //state.fetchType = a as any;
        },
    },

});

export const selectAllProjectRequirements = (state: Store): ProjectRequirementsType[] =>
    state.projectRequirements.fetchType;
export const { addRequirements, deleteRequirements, updateRequirements } =
    projectRequirementsSlice.actions;
export default projectRequirementsSlice.reducer;
