import * as React from 'react';
import { DataGridPremium, GridRowEditStopReasons } from '@mui/x-data-grid-premium';
import type {
    DataGridPremiumProps,
    GridEventListener,
    GridRowModel,
    GridRowModesModel,
    GridSlots,
} from '@mui/x-data-grid-premium';
import type { EmployeeType, ProjectType, Store } from '../../types';
import {
    fetchGetProjects,
    fetchPostProjects,
    selectAllProjects,
    addProject as setRows,
    updateProject,
} from '../slices/projectsSlice';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import DetailPanelContent from './project';
import EditToolbar from '../components/editToolbar';
import type { FetchStatusType } from '../../types';
import type { GridColDef } from '@mui/x-data-grid-premium';
import { selectAllEmployees } from '../slices/employeesSlice';
import SettingDialog from '../components/settingDialog';
import type { SettingsType } from '../components/settingDialog';
import type { UnknownAction } from '@reduxjs/toolkit';
//import columns from './projectComponents/projectsColumns';

const { useCallback, useEffect, useState } = React;
const DETAIL_PANEL_HIGHT = 800;

export const Projects = (): React.JSX.Element => {
    const dispatch = useDispatch();
    const getDetailPanelHeight = useCallback(() => DETAIL_PANEL_HIGHT, []);
    const [settings] = useState<string>('');
    const [openAddOptions, setOpenAddOptions] = useState<boolean>(false);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const fetchStatus: FetchStatusType = useSelector((state: Store) => state.projects.status);
    const rows = useSelector(selectAllProjects).fetchType ?? [];
    const employees: EmployeeType[] = useSelector(selectAllEmployees) ?? [];

    useEffect(() => {
        if (fetchStatus === 'idle') {
            dispatch(fetchGetProjects() as unknown as UnknownAction);
        }
    }, []);

    useEffect(() => {
        if (fetchStatus !== 'succeeded') {
            return;
        }
        const saveRows: { projects: ProjectType[] } = {
            projects: rows.map((row) => ({
                description: row.description,
                duration: row.duration,
                id: row.id,
                isActive: row.isActive,
                mainContact: row.mainContact,
                pid: row.pid,
                progress: row.progress,
                projectName: row.projectName,
                stackRank: row.stackRank,
                startDate: row.startDate,
                status: row.status,
                type: row.type,
            })),
        };

        dispatch(fetchPostProjects(saveRows) as unknown as UnknownAction);
    }, [rows]);

    const getDetailPanelContent = useCallback<
        NonNullable<DataGridPremiumProps['getDetailPanelContent']>
    >(({ row }: { row: ProjectType }) => <DetailPanelContent project={row} projects={rows} />, []);

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel): void => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const processRowUpdate = (
        newRow: GridRowModel,
    ): {
        isNew: boolean;
    } => {
        const updatedRow = {
            ...newRow,
            isNew: false,
        };
        dispatch(updateProject(newRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'pid',
            headerName: 'ID',
            type: 'number',
        },
        {
            field: 'isActive',
            headerName: 'Is Active',
            type: 'boolean',
        },
        {
            field: 'stackRank',
            headerName: 'Stack Rank',
            type: 'string',
        },
        {
            field: 'projectName',
            headerName: 'Project Name',
            type: 'string',
        },
        {
            field: 'projectTypes',
            headerName: 'Type',
            type: 'string',
        },
        {
            field: 'description',
            headerName: 'Description',
            type: 'string',
        },
        {
            field: 'mainContact',
            headerName: 'Main Contact',
            type: 'string',
            valueGetter: (value, row): string => {
                const name = employees.find(e => e.eid === row.mainContact)?.name ?? '';
                return name;
            },
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            type: 'string',
        },
        {
            field: 'duration',
            headerName: 'Duration',
            type: 'string',
        },
        {
            field: 'status',
            headerName: 'Status',
            type: 'string',
        },
        {
            field: 'progress',
            headerName: 'Progress',
            type: 'string',
        },
    ];

    return (
        <>
            <SettingDialog
                setting={settings as SettingsType}
                show={openAddOptions}
                setOpenAddOptions={setOpenAddOptions}
            />

            <Box
                sx={{
                    height: 1300,
                    width: '100%',
                }}
            >
                <DataGridPremium
                    pagination
                    autoPageSize
                    columns={columns}
                    rows={rows}
                    editMode='row'
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    getDetailPanelHeight={getDetailPanelHeight}
                    getDetailPanelContent={getDetailPanelContent}
                    slots={{
                        toolbar: EditToolbar as GridSlots['toolbar'],
                    }}
                    slotProps={{
                        toolbar: {
                            setRowModesModel,
                            setRows,
                        },
                    }}
                    sx={{ flex: 1 }}
                    density='compact'
                />
            </Box>
        </>
    );
};

export default Projects;

/* Resource Magic 2024 */