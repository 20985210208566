import type { FetchType, SkillLevelsType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';

export const fetchGetSkillLevels = createAsyncThunk<SkillLevelsType[]>(
    'skillLevels/getLevels',
    async () => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',
        };
        const response = await fetch(`${SERVER_IP}/settings/project_settings/level`, init);
        const data = (await response.json()) as SkillLevelsType[];
        return data;
    },
);

export const fetchPostSkillLevels = createAsyncThunk(
    'skillLevels/postLevels',
    async (saveRows: { skill_levels: SkillLevelsType[] }) => {
        const init: RequestInit = {
            body: JSON.stringify(saveRows),
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'POST',
        };
        const data = await fetch(`${SERVER_IP}/settings/project_settings/level`, init);
        const json = (await data.json()) as Promise<unknown>;
        console.log(json);
    },
);

const initialState: FetchType<SkillLevelsType[]> = {
    error: null,
    fetchType: [] as SkillLevelsType[],
    status: 'idle',
};

export const skillLevelsSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetSkillLevels.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetSkillLevels.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetSkillLevels.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPostSkillLevels.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostSkillLevels.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchPostSkillLevels.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'skillLevels',
    reducers: {
        addSkillLevel: (state, action) => {
            const { id } = action.payload;
            state.fetchType.push({
                description: '',
                id,
                isNew: true,
                level: '',
            });
        },
        deleteSkillLevel: (state, action) => {
            state.fetchType = state.fetchType?.filter((row) => row.id !== action.payload);
        },
        updateSkillLevel: (state, action) => {
            const skillLevel = action.payload as SkillLevelsType;
            const updatedRow: SkillLevelsType = {
                ...skillLevel,
                isNew: false
            };
            state.fetchType = state.fetchType.map((row) =>
                row.id === skillLevel.id ? updatedRow : row);
        },
    },

});

export const selectAllSkillLevels = (state: Store): SkillLevelsType[] =>
    state.skillLevels.fetchType;
export const { deleteSkillLevel, updateSkillLevel, addSkillLevel } = skillLevelsSlice.actions;
export default skillLevelsSlice.reducer;
