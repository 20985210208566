import type { Dayjs } from 'dayjs';
export const SERVER_IP = 'https://julyasoft.com:443';

export type FetchStatusType = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface Store {
    skillLevels: FetchType<SkillLevelsType[]>;
    skillCategories: FetchType<SkillCategoryType[]>;
    skills: FetchType<SkillsType[]>;

    contactTypes: FetchType<ContactTypesType[]>;
    jobTitles: FetchType<JobTitleType[]>;

    projects: FetchType<ProjectType[]>;
    projectStatus: FetchType<ProjectStatusType[]>;
    projectTypes: FetchType<ProjectTypesType[]>;
    projectCompletionStatus: FetchType<ProjectCompletionStatusType[]>;
    projectRequirements: FetchType<ProjectRequirementsType[]>;

    employees: FetchType<EmployeeType[]>;
    employeeSkills: FetchType<EmployeeSkillsType[]>;
    employeeContacts: FetchType<EmployeeContactType>[];
    employeeSchedule: FetchType<EmployeeScheduleType>[];
    employeeRoles: FetchType<ResourceRoleType[]>;
    employeeExpertise: FetchType<DomainExpertiseType[]>;
}

export interface SkillSubCategoryType {
    sub_category: string;
    description: string;
}

export interface SkillLevelsType {
    id: string;
    level: string;
    description: string;
    isNew?: boolean;
}

export interface SkillCategoryType {
    id: string;
    category: string;
    description: string;
    subCategories?: {
        id: string;
        subcategory: string;
        description: string;
        isNew?: boolean;
    }[];
    isNew?: boolean;
}

export interface SkillsType {
    id: string;
    skill: string;
    version: string;
    description: string;
    priority?: Priority;
    isNew?: boolean;
}

export interface ProjectTypesType {
    id: string;
    projectType: string;
    description: string;
    isNew?: boolean;
}

export interface ResourceRoleType {
    id: string;
    employeeRole: string;
    description: string;
    isNew?: boolean;
}

export interface DomainExpertiseType {
    id: string;
    employeeExpertise: string;
    description: string;
    isNew?: boolean;
}

export interface AddressType {
    addressOne: string;
    addressTwo: string;
    city: string;
    state: string;
    zip: string;
    country: string;
}

export interface EmailType {
    email: string;
    contactType: EmailContactType;
    priority: Priority;
}

export interface EmployeeSkills {
    skill: string;
    version: string;
    categories?: string;
    level: string;
    priority?: Priority;
}

export const emailContactsType = ['Office', 'Home', 'Other'] as const;
export type EmailContactType = (typeof emailContactsType)[number];

export type Priority = 'Primary' | 'Secondary' | '';

export interface PhoneType {
    phone: string;
    contactType: PhoneContactType;
    priority: Priority;
}

export const phoneContactType = ['Office', 'Home', 'Cell', 'Other'] as const;
export type PhoneContactType = (typeof phoneContactType)[number];

export interface ContactTypesType {
    id: string;
    contactType: string;
    description: string;
    isNew?: boolean;
}

export interface JobTitleType {
    id: string;
    jobTitle: string;
    description: string;
    isNew?: boolean;
}

export interface EmployeeIdType {
    name: string;
    eid: number;
}

/*** NEW INTERFACE */

/** FetchType */
export interface FetchType<T> {
    fetchType: T;
    status: FetchStatusType;
    error: string | undefined | null;
}

/** Project */
export interface ProjectType {
    id?: string; // id of the row
    isActive: boolean;
    stackRank: string; // Unique Number, autogenerated
    projectName: string; // Project Name
    type: string; // Project Type. Enumeration can be updated on fly (approved, padding, planning)
    mainContact: number; // Main Contact. Can be selected or added. employee eid
    startDate: Dayjs | null; // Start Date of the Project
    duration: number | string; // Duration in days.
    status: string; // Status. Green complied, red conflict, yellow not complied
    pid: number; //
    progress: number | string; // Chart shows progress and percentage
    description: string;
    isNew?: boolean;
}

/** Project Requirements */
export interface RequirementsForProjectType {
    id?: string;
    employeeRole: string;
    employeeExpertise: string;
    skill: string;
    skillLevel: string;
    skillCategory: string;
    resources: number;
    startDate: Date;
    duration: number;
    isNew?: boolean;
}

export interface ProjectRequirementsType {
    stackRank: string;
    requirements: RequirementsForProjectType[];
}

/** Project Status */
export interface ProjectStatusType {
    id?: string;
    status: string;
    description: string;
    isNew?: boolean;
}

/** Employee */
export interface EmployeeType {
    id?: string;
    isNew?: boolean;
    eid: number;
    isActive: boolean;
    name: string;
    email: string;
    phone: string;
    notes: string;
    jobTitle: string;
    roles: string[];
    domains: string[];
}

/** Employee Contact */
export interface EmployeeContactType {
    id?: string;
    eid: number;
    phones?: PhoneType[];
    emails?: EmailType[];
    address?: AddressType[];
}

/** Employee Skills */
export interface EmployeeSkillsType {
    eid: number;
    skills: {
        id?: string;
        skill: string;
        level: string;
        category: string;
        description: string;
        isNew?: boolean;
    }[];
}

// Schedule
export interface HighLightType {
    day: string;
    projects?: string[];
    blockReason?: string;
}

export interface EmployeeScheduleType {
    eid: number;
    daySlots: HighLightType[];
}

// Project Compilation
export interface ProjectCompletionStatusType {
    stackRank: string;
    completionStatus: CompletionStatusForProjectType[];
}

export interface CompletionStatusForProjectType {
    id?: string;
    isNew?: boolean;
    eid: number;
    //name?: string;
    role: string;
    domain: string;
    skill: string;
    level: string;
    duration: number;
}