import { configureStore } from '@reduxjs/toolkit';
import contactTypesReducer from './main/slices/contactTypesSlice';
import domainExpertiseReducer from './main/slices/employeeExpertiseSlice';
import employeeContactsReducer from './main/slices/employeeContactSlice';
import employeeScheduleReducer from './main/slices/employeeScheduleSlice';
import employeeSkillsReducer from './main/slices/employeeSkillsSlice';
import employeesReducer from './main/slices/employeesSlice';
import jobTitlesReducer from './main/slices/employeeTitlesSlice';
import projectCompletionStatusReducer from './main/slices/projectCompletionSlice';
import projectRequirementsReducer from './main/slices/projectRequirementsSlice';
import projectStatusReducer from './main/slices/projectStatusSlice';
import projectTypesReducer from './main/slices/projectTypesSlice';
import projectsReducer from './main/slices/projectsSlice';
import resourceRoleReducer from './main/slices/employeeRolesSlice';
import skillCategoriesReducer from './main/slices/skillCategoriesSlice';
import skillLevelsReducer from './main/slices/skillLevelsSlice';
import skillsReducer from './main/slices/skillsSlice';

export default configureStore({
    reducer: {
        contactTypes: contactTypesReducer,

        employeeContacts: employeeContactsReducer,
        employeeExpertise: domainExpertiseReducer,
        employeeRoles: resourceRoleReducer,
        employeeSchedule: employeeScheduleReducer,
        employeeSkills: employeeSkillsReducer,
        employees: employeesReducer,

        jobTitles: jobTitlesReducer,

        projectCompletionStatus: projectCompletionStatusReducer,
        projectRequirements: projectRequirementsReducer,
        projectStatus: projectStatusReducer,
        projectTypes: projectTypesReducer,
        projects: projectsReducer,

        skillCategories: skillCategoriesReducer,
        skillLevels: skillLevelsReducer,
        skills: skillsReducer,
    },
});
