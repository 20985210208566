import type { FetchType, ProjectType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';

export const fetchGetProjects = createAsyncThunk<ProjectType[]>('project/getProjects', async () => {
    const init: RequestInit = {
        headers: new Headers({ 'content-type': 'application/json' }),

        method: 'GET',
    };
    const response = await fetch(`${SERVER_IP}/project/projects`, init);
    const data = (await response.json()) as ProjectType[];
    return data;
});

export const fetchPostProjects = createAsyncThunk(
    'project/postProjects',
    async (saveRows: { projects: ProjectType[] }) => {
        const init: RequestInit = {
            body: JSON.stringify(saveRows),
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'POST',
        };

        const data: Response = await fetch(`${SERVER_IP}/project/projects`, init);
        return data.json();
    },
);

const initialState: FetchType<ProjectType[]> = {
    error: null,
    fetchType: [] as ProjectType[],
    status: 'idle',
};

export const projectsSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetProjects.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetProjects.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetProjects.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPostProjects.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostProjects.fulfilled, (state, action) => {
                state.status = 'idle';
                state.fetchType = action.payload;
            })
            .addCase(fetchPostProjects.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'project',
    reducers: {
        addProject: (state, action) => {
            const { id } = action.payload;
            const newProject: ProjectType = {
                description: '',
                duration: '',
                id,
                isActive: true,
                isNew: true,
                mainContact: -1,
                pid: -1,
                progress: '',
                projectName: '',
                stackRank: '',
                startDate: null,
                status: '',
                type: '',
            };
            state.fetchType.push(newProject);
        },
        deleteProject: (state, action) => {
            state.fetchType = state.fetchType?.filter((row) => row.id !== action.payload);
        },
        updateProject: (state, action) => {
            const updatedRow: ProjectType = {
                ...(action.payload as ProjectType),
                isNew: false
            };
            state.fetchType = state.fetchType.map((row) =>
                row.id === (action.payload as ProjectType).id ? updatedRow : row,
            );
        },
    },

});

export const selectAllProjects = (state: Store): FetchType<ProjectType[]> => state.projects;
export const { deleteProject, updateProject, addProject } = projectsSlice.actions;
export default projectsSlice.reducer;
