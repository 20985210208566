import type { GridColDef } from '@mui/x-data-grid-premium';

export const EmployeeColumns: GridColDef[] = [
    {
        field: 'eid',
        headerName: 'Employee ID',
        type: 'string'
    },
    {
        field: 'isActive',
        headerName: 'Is Active',
        type: 'boolean'
    },
    {
        field: 'name',
        headerName: 'Name',
        type: 'string'
    },
    {
        field: 'jobTitle',
        headerName: 'Jpb Title',
        type: 'string'
    },
    {
        field: 'roles',
        headerName: 'Roles',
        type: 'string'
    },
    {
        field: 'domains',
        headerName: 'Domains',
        type: 'string'
    },
    {
        field: 'phone',
        headerName: 'Phone',
        type: 'string'
    },
    {
        field: 'email',
        headerName: 'Email',
        type: 'string'
    },
    {
        field: 'notes',
        headerName: 'Notes',
        type: 'string'
    },
];

export default EmployeeColumns;