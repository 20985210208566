import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';

interface EnhancedTableToolbarProps<T> {
    setSelectedRows: React.Dispatch<React.SetStateAction<T[]>>;
    selectedRows: T[];
    setRows: React.Dispatch<React.SetStateAction<T[]>>;
}

export const EnhancedTableToolbar = <T,>(props: EnhancedTableToolbarProps<T>) => {
    const { setSelectedRows, selectedRows, setRows } = props;
    const onClick = (): void => {
        setSelectedRows([]);
        setRows((prevRows) => {
            const newRows = prevRows.filter((row) => !selectedRows.includes(row));
            return newRows;
        });
    };

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: {
                    xs: 1,
                    sm: 1
                },
                ...selectedRows.length > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                },
            }}
        >
            {selectedRows.length > 0 ?
                <Typography
                    sx={{ flex: '1 1 300' }}
                    color='inherit'
                    variant='subtitle1'
                    component='div'
                >
                    {selectedRows.length} selected
                </Typography>
                :
                <></>
            }
            {selectedRows.length > 0 ?
                <Tooltip title='Delete'>
                    <IconButton onClick={onClick}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                :
                <></>
            }
        </Toolbar>
    );
};

export default EnhancedTableToolbar;
