import type { FetchType, ResourceRoleType, Store } from '../../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_IP } from '../../types';

export const fetchGetEmployeeRoles = createAsyncThunk<ResourceRoleType[]>(
    'project_settings/getEmployeeRoles',
    async () => {
        const init: RequestInit = {
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'GET',
        };
        const response = await fetch(`${SERVER_IP}/settings/project_settings/employee_roles`, init);
        const data = (await response.json()) as ResourceRoleType[];
        return data;
    },
);

export const fetchPostEmployeeRoles = createAsyncThunk(
    'project_settings/postEmployeeRoles',
    async (saveRows: { employee_roles: ResourceRoleType[] }) => {
        const init: RequestInit = {
            body: JSON.stringify(saveRows),
            headers: new Headers({ 'content-type': 'application/json' }),

            method: 'POST',
        };

        const data = await fetch(`${SERVER_IP}/settings/project_settings/employee_roles`, init);
        console.log(await data.json());
    },
);

const initialState: FetchType<ResourceRoleType[]> = {
    error: null,
    fetchType: [] as ResourceRoleType[],
    status: 'idle',
};

export const employeeRolesSlice = createSlice({
    extraReducers(builder) {
        builder
            .addCase(fetchGetEmployeeRoles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGetEmployeeRoles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchType = action.payload;
            })
            .addCase(fetchGetEmployeeRoles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPostEmployeeRoles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostEmployeeRoles.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(fetchPostEmployeeRoles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
    initialState,
    name: 'employeeRoles',
    reducers: {
        addResourceRole: (state, action) => {
            const { id } = action.payload;
            state.fetchType.push({
                description: '',
                employeeRole: '',
                id,
                isNew: true,
            });
        },
        deleteResourceRole: (state, action) => {
            state.fetchType = state.fetchType?.filter((row) => row.id !== action.payload);
        },
        updateResourceRole: (state, action) => {
            const role = action.payload as ResourceRoleType;
            const updatedRow = {
                ...role,
                isNew: false
            };
            state.fetchType = state.fetchType.map((row) =>
                row.id === role.id ? updatedRow : row,
            );
        },
    },
});

export const selectAllEmployeeRoles = (state: Store): ResourceRoleType[] =>
    state?.employeeRoles?.fetchType ?? [];
export const { deleteResourceRole, updateResourceRole, addResourceRole } =
    employeeRolesSlice.actions;
export default employeeRolesSlice.reducer;
